import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from "material-ui-confirm";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useEffect, useState } from 'react';
import * as userHttpServices from '../../../src/services/user_management_service';
import { useNavigate } from "react-router-dom";
const columns = [
    { id: 'no', label: 'No', width: '3%' },
    { id: 'username', label: 'User Name', width: '10%' },
    { id: 'name', label: 'Name', width: '10%' },
    { id: 'role', label: 'Role', width: '15%' },
    { id: 'status', label: 'Status', width: '12%' },
    { id: 'button1', width: '17%' },
    { id: 'button2', width: '10%' },
    { id: 'button3', width: '10%' }
];

export default function StickyHeadTable() {
    const confirm = useConfirm();
    const navigate = useNavigate()
    const [users, setUsers] = useState('')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [checked, setChecked] = useState(true);
    let token = localStorage.getItem('token')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    useEffect(() => {
        userHttpServices.getUsers(token)
            .then((res) => {
                console.log(res)
                setUsers(res.data.data)
            })
    }, [])
    const handleChange = (e, row) => {
        let user_id = row.user_id
        let username = row.Username
        let active = e.target.checked
        let data = {
            user_id: user_id,
            active: active
        }
        confirm({ description: `Confirm  to update status ${username}.` })
            .then(() => {
                userHttpServices.onSetActive(data, token)
                    .then((res) => {
                        if (res.data.response.status) {
                            alert(' Update Success')
                            userHttpServices.getUsers(token)
                                .then((res) => {
                                    setUsers(res.data.data)
                                })
                        }
                    })

            })
            .catch((err) => console.log("Update fail.", err))
    };

    const role = (role_id) => {
        let result
        switch (role_id) {
            case 1:
                result = 'Collector Supervisor'
                break;
            case 2:
                result = 'Collector Operator'
                break;
            default:
            // code block
        }

        return result
    }
    const goEditPage = (e, row) => {
        navigate("/user_management/edit_user", {
            state: row,
        })
    }
    const goResetPassword = (e, row) => {
        navigate("/user_management/reset_password", {
            state: row,
        })
    }
    const onDelete = (e, row) => {
        let user_id = row.user_id
        let username = row.Username

        confirm({ description: `Confirm  to  delete ${username}.` })
            .then(() => {
                userHttpServices.onDeleteUser(user_id, token)
                    .then((res) => {
                        if (res.data.response.status) {
                            alert('Delete User Success')
                            userHttpServices.getUsers(token)
                                .then((res) => {
                                    setUsers(res.data.data)
                                })
                        }
                    })

            })
            .catch((err) => console.log("Delete fail.", err))
    }
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }} style={{ marginTop: '1.5rem' }} className="shadow mb-5 bg-white rounded">
            {console.log('return')}
            <TableContainer sx={{ maxHeight: 880 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ width: column.width }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(users).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell >{index + 1}</TableCell>
                                    <TableCell >{row.Username}</TableCell>
                                    <TableCell >{row.Name}</TableCell>
                                    <TableCell >{role(row.role_id)}</TableCell>
                                    {/* <TableCell > <FormControlLabel control={<Switch checked={true} onChange={handleChange} />} label="Label" /></TableCell> */}
                                    {console.log(row.Active === 0)}
                                    <TableCell > <FormControlLabel control={<Switch checked={row.active === 0 ? false : true} onChange={(e) => handleChange(e, row)} color="success" />} label={row.Active === 0 ? "Inactive" : "Active"} /></TableCell>
                                    {/* <TableCell > <FormControlLabel control={<Switch checked={checked} onChange={handleChange(index)} color="success" />} label={row.Active === 0 ? "Inactive" : "Active"} /></TableCell> */}

                                    <TableCell > <Button variant="text" onClick={(e) => goResetPassword(e, row)}><CachedIcon />{'\u00a0'}Reset Password</Button></TableCell>
                                    <TableCell > <Button variant="text" onClick={(e) => goEditPage(e, row)}><EditIcon />{'\u00a0'}Edit</Button></TableCell>
                                    <TableCell > <Button variant="text" onClick={(e) => onDelete(e, row)}><DeleteIcon />{'\u00a0'}Delete</Button></TableCell>

                                    {/* <td style={{ textAlign: 'center' }}><button id='btn-detail' className="btn btn-primary" onClick={() => handleClickDetail(data[0].ncb_files['ncb' + (index + 1)].ncb_id, index + 1)}>รายละเอียด</button></td> */}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}