/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import * as userHttpServices from '../../../src/services/services';

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../page/header';
import './../sum.css'
import {
    Card,
    Col,
    Row,
    Button,
    Container,
    // Form,
    Alert,
    ListGroup
} from 'react-bootstrap';

import Table from 'react-bootstrap/Table';

function convert_type(type) {
    if (type === '1') return 'หญิง'
    else if (type === '2') return 'ชาย'
    else if (type === '0001') return 'สมรส'
    else if (type === '0002') return 'โสด'
    else return 'ไม่ระบุ'
}

function convert_account_status(type) {
    if (type === '10') return 'ปกติ'
    else if (type === '11') return 'ปิดบัญชี'
    else if (type === '12') return 'พักชำระหนี้ตามนโยบาลของสมาชิก'
    else if (type === '13') return 'พักชำระหนี้ตามนโยบายของรัฐ'
    else if (type === '14') return 'พักชำระหนี้เกษตรกรตามนโยบายของรัฐ'
    else if (type === '20') return 'หนี้ค้างชำระเกิน 90 วัน'
    else if (type === '30') return 'อยู่ในกระบวนการทางกฎหมาย'
    else if (type === '31') return 'อยู่ระหว่างชำระหนี้ตามคำพิพากษายอม'
    else if (type === '32') return 'ศาลพิพากษายกฟ้องเนื่องจากขาดอายุความ หรือเหตุอื่น เว้นแต่ยกฟ้องเนื่องจากความเป็นหนี้มิได้มีอยู่จริง'
    else if (type === '33') return 'ปิดบัญชีเนื่องจากตัดหนี้สูญ'
    else if (type === '40') return 'อยู่ระหว่างชำระสินเชื่อปิดบัญชี'
    else if (type === '41') return 'เจ้าของข้อมูลขอตรวจสอบรายการ'
    else if (type === '42') return 'โอนหรือขายหนี้'
    else if (type === '43') return 'โอนหนี้ขายหนี้ และชำระหนี้เสร็จสิ้น'
    else return null
}

function convert_account_type(type) {
    if (type === '01') return 'สินเชื่อเพื่อการพาณิชย์'
    else if (type === '04') return 'สินเชื่อวงเงินเบิกเกินบัญชี'
    else if (type === '05') return 'สินเชื่อบุคคล'
    else if (type === '06') return 'สินเชื่อที่อยู่อาศัย'
    else if (type === '07') return 'สินเชื่อเพื่อซื้อ/ยืมหลักทรัพย์'
    else if (type === '08') return 'สินเชื่อตั๋วเงินในประเทศ'
    else if (type === '09') return 'สินเชื่อซื้อลดตั๋วเงิน'
    else if (type === '10') return 'สินเชื่อรับซื้อตั๋วเงินสินค้าเข้า'
    else if (type === '11') return 'สินเชื่อรับซื้อตั๋วเงินสินค้าส่งออก'
    else if (type === '12') return 'สินเชื่อการรับซื้อตั๋วเงินค่าสินค้าเข้าที่ทำทรัส์รีซีท'
    else if (type === '13') return 'สินเชื่อรับซื้อตั๋วเงินอื่นๆ'
    else if (type === '14') return 'สินเชื่อเพื่อเตรียมการส่งออกตาม - L/C'
    else if (type === '15') return 'เงินชดใช้ตามภาระผูกพัน - Aval'
    else if (type === '16') return 'เงินชดใช้ตามภาระผูกพัน - '
    else if (type === '17') return 'เงินชดใช้ตามภาระผูกพัน - L/C'
    else if (type === '18') return 'เงินชดใช้ตามภาระผูกพัน - L/G'
    else if (type === '19') return 'เงินชดใช้ตามภาระผูกพันอื่นๆ'
    else if (type === '20') return 'สินเชื่อให้เช่าแบบลีสซิ่งรถยนต์'
    else if (type === '21') return 'สินเชื่อเช่าอื่นๆ'
    else if (type === '22') return 'สินเชื่อบัตรเครคิต'
    else if (type === '27') return 'สินเชื่อเช่ารถยนต์'
    else if (type === '28') return 'วงเงินสินเชื่อ'
    else if (type === '29') return 'สินเชื่อให้เช่าแบบลีสซิ่งอื่นๆ'
    else if (type === '31') return 'สินเชื่อเช่าซื้อเพื่อการเกษตรที่มีจำนวนเงินชำระต่องวดไม่คงที่'
    else if (type === '32') return 'สินเชื่อเช่าซื้อเพื่อการเกษตร'
    else if (type === '33') return 'สินเชื่อเพื่อการเกษตร'
    else if (type === '34') return 'สินเชื่อแฟ็กเตอริ่ง'
    else if (type === '35') return 'สัญญาซื้อขายล่วงหน้า'
    else if (type === '36') return 'เงินกู้สหกรณ์'
    else if (type === '37') return 'สินเชื่อรายย่อยเพื่อการประกอบอาชีพ'
    else if (type === '38') return 'การค้ำประกันเงินกู้'
    else if (type === '50') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อเพื่อการพาณิชย์'
    else if (type === '51') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อบุคคล'
    else if (type === '52') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อที่อยู่อาศัย'
    else if (type === '53') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อให้เช่าแบบลีสซิ่งรถยนต์'
    else if (type === '54') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อเช่าซื้ออื่นๆ'
    else if (type === '55') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อบัตรเครคิต'
    else if (type === '56') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อให้เช่ารถยนต์'
    else if (type === '57') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อให้เช่าแบบลีสซิ่งอื่นๆ'
    else if (type === '58') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อวงเงินเบิกเกินบัญชี'
    else if (type === '90') return 'สินเชื่อที่มีการปรับปรุงโครงสร้างหนี้'
    else if (type === '99') return 'สินเชื่ออื่นๆ'
    else return null
}

function convert_ownershipIndicator_type(type) {
    if (type === '1') return 'กู้เดียว'
    else if (type === '2') return 'Supplementary Card'
    else if (type === '4') return 'กู้ร่วม'
    else if (type === 'A') return 'DSPT Individual'
    else if (type === 'B') return 'DSPT Supplementary Card'
    else if (type === 'C') return 'Remark Closed Account at AMC'
    else if (type === 'D') return 'DSPT Joint'
    else if (type === 'F') return 'INVS Individual'
    else if (type === 'G') return 'INVS Supplementary Card'
    else if (type === 'H') return 'INVS Authorized'
    else if (type === 'I') return 'INVS Joint'
    else return null
}

function convert_type_payment(type) {
    if (type === '000') return <><span style={{ backgroundColor: '#489b71', color: '#FFFFFF' }}>&nbsp;0&nbsp;</span>&ensp;ไม่ค้างชำระหรือค้างชำระไม่เกิน 30 วัน</>
    else if (type === '001') return <><span style={{ backgroundColor: '#f29d38', color: '#FFFFFF' }}>&nbsp;1&nbsp;</span>&ensp;ค้างชำระ 31-60 วัน</>
    else if (type === '002') return <><span style={{ backgroundColor: '#ee6f2d', color: '#FFFFFF' }}>&nbsp;2&nbsp;</span>&ensp;ค้างชำระ 61-90 วัน</>
    else if (type === '003') return <><span style={{ backgroundColor: '#ec4726', color: '#FFFFFF' }}>&nbsp;3&nbsp;</span>&ensp;ค้างชำระ 91-120 วัน</>
    else if (type === '004') return <><span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;4&nbsp;</span>&ensp;ค้างชำระ 121-150 วัน</>
    else if (type === '005') return <><span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;5&nbsp;</span>&ensp;ค้างชำระ 151-180 วัน</>
    else if (type === '006') return <><span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;6&nbsp;</span>&ensp;ค้างชำระ 181-210 วัน</>
    else if (type === '007') return <><span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;7&nbsp;</span>&ensp;ค้างชำระ 211-240 วัน</>
    else if (type === '008') return <><span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;8&nbsp;</span>&ensp;ค้างชำระ 241-270 วัน</>
    else if (type === '009') return <><span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;9&nbsp;</span>&ensp;ค้างชำระ 271-300 วัน</>
    else if (type === '  F') return <><span style={{ backgroundColor: '#333333', color: '#FFFFFF' }}>&nbsp;F&nbsp;</span>&ensp;ค้างชำระมากกว่า 300 วัน</>

    else if (type === '  N') return <><span style={{ backgroundColor: '#489b71', color: '#FFFFFF' }}>&nbsp;N&nbsp;</span>&ensp;ยอดหนี้ไม่เกินวงเงิน</>
    else if (type === '  Y') return <><span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;Y&nbsp;</span>&ensp;ยอดหนี้เกินวงเงิน</>
}

function icon(type) {
    if (type === '000') return <span style={{ backgroundColor: '#489b71', color: '#FFFFFF' }}>&nbsp;0&nbsp;</span>
    else if (type === '001') return <span style={{ backgroundColor: '#f29d38', color: '#FFFFFF' }}>&nbsp;1&nbsp;</span>
    else if (type === '002') return <span style={{ backgroundColor: '#ee6f2d', color: '#FFFFFF' }}>&nbsp;2&nbsp;</span>
    else if (type === '003') return <span style={{ backgroundColor: '#ec4726', color: '#FFFFFF' }}>&nbsp;3&nbsp;</span>
    else if (type === '004') return <span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;4&nbsp;</span>
    else if (type === '005') return <span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;5&nbsp;</span>
    else if (type === '006') return <span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;6&nbsp;</span>
    else if (type === '007') return <span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;7&nbsp;</span>
    else if (type === '008') return <span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;8&nbsp;</span>
    else if (type === '009') return <span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;9&nbsp;</span>
    else if (type === '  F') return <span style={{ backgroundColor: '#333333', color: '#FFFFFF' }}>&nbsp;F&nbsp;</span>

    else if (type === '  N') return <span style={{ backgroundColor: '#489b71', color: '#FFFFFF' }}>&nbsp;N&nbsp;</span>
    else if (type === '  Y') return <span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;Y&nbsp;</span>
}

export default function App() {
    const [data, setData] = useState()
    const { contact_id } = useParams()
    const [isShown, setIsShown] = useState(false);

    const getToken = () => {
        let token = localStorage.getItem('token')
        return token
    }

    useEffect(() => {
        userHttpServices.detail1(contact_id, getToken())
            .then((res) => {
                console.log(res)
                setData(res.data)
            })
    }, [])

    function handleClick(id) {
        let x = document.getElementById(id)

        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }

    function handleDoubleClick() {
        setIsShown(current => !current);
    }

    function alert1() {
        if (data.calculated_data.other.alert1) {
            return (
                <Alert variant="danger" style={{ marginBottom: '2rem' }}>
                    <Alert.Heading style={{ paddingTop: '0.5rem' }}><h3>แจ้งเตือน!</h3></Alert.Heading>
                    <p className={'small-text'}>
                        ลูกค้าท่านนี้ มียอดหนี้ที่ค้างชำระเกิน 20% ของยอดหนี้ทั้งหมด!
                    </p>
                </Alert>
            )
        }
    }

    function alert2() {
        if (data.calculated_data.other.alert2) {
            return (
                <Alert variant="danger" style={{ marginBottom: '2rem' }}>
                    <Alert.Heading style={{ paddingTop: '0.5rem' }}><h3>แจ้งเตือน!</h3></Alert.Heading>
                    <p className={'small-text'}>
                        ลูกค้าท่านนี้ มีประวัติ สถานะบัญชี 43: โอนหรือขายหนี้และชำระหนี้เสร็จสิ้น
                    </p>
                </Alert>
            )
        }
    }

    function alert3() {
        if (data.calculated_data.other.alert3) {
            return (
                <Alert variant="danger" style={{ marginBottom: '2rem' }}>
                    <Alert.Heading style={{ paddingTop: '0.5rem' }}><h3>แจ้งเตือน!</h3></Alert.Heading>
                    <p className={'small-text'}>
                        ลูกค้าท่านนี้ มีประวัติ สถานะบัญชี 42: โอนหรือขายหนี้
                    </p>
                </Alert>
            )
        }
    }

    if (data === undefined) {
        return <div style={{ margin: '2rem' }}>Loading & Calculate NCB Data...</div>;
    }
    return (
        <>
            <Container>
                <div className='header'>
                    <Header />
                </div>
                <Card style={{ marginTop: '6rem' }} className="shadow p-3 mb-5 bg-white rounded">
                    <Card.Body>
                        {alert1()}
                        {alert2()}
                        {alert3()}

                        <Card.Title as='h1' name='summarize'>ข้อมูล NCB ชุดที่ 1</Card.Title>

                        <div className='small-text' style={{ color: '#909497', marginTop: '1.5rem' }}>วันที่สืบค้น: {data.data?.header?.enquityDate}&emsp;ชื่อ-นามสกุล: {data.data?.name?.N01?.firstName}{' '}{data.data?.name?.N01?.familyName1}&emsp;เลขบัตรประชาชน: {data.data?.id?.ID1?.IDNumber}&emsp;วันเกิด: {data.data?.name?.N01?.dateOfBirth}&emsp;เพศ: {convert_type(data.data?.name?.N01?.gender)}&emsp;สถานภาพ: {convert_type(data.data?.name?.N01?.maritalStatus)}&emsp;อายุ: {data.calculated_data?.other?.age}</div>

                        <Card.Title as='h3' name='credit_detail' style={{ marginTop: '1.5rem' }}>ข้อมูลประกอบการตัดสินใจ</Card.Title>
                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card border={parseInt(data.calculated_data.ncb_score.ncb_score) < 616 ? 'danger' : 'success'} style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem', color: parseInt(data.calculated_data.ncb_score.ncb_score) < 616 ? 'red' : 'green' }} as='h6'>NCB Score สรุป</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right', color: parseInt(data.calculated_data.ncb_score.ncb_score) < 616 ? 'red' : 'green' }} as='h3'>{parseInt(data.calculated_data.ncb_score.ncb_score)}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card border={data.calculated_data.other_detail.amountPastDue > 0 ? 'danger' : 'success'} style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem', color: parseInt(data.calculated_data.other_detail.amountPastDue) > 0 ? 'red' : 'green' }} as='h6'>ยอดหนี้ที่เกินกำหนดชำระ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right', color: parseInt(data.calculated_data.other_detail.amountPastDue) > 0 ? 'red' : 'green' }} as='h3'>{data.calculated_data.other_detail.amountPastDue.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดหนี้คงเหลือ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.amountOwed.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='ncb' style={{ marginTop: '1.5rem' }}>NCB Score</Card.Title>
                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>NCB Score</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{parseInt(data.calculated_data.ncb_score.ncb_score)}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Company score</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.ncb_score.ncb_pico_score}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">

                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Weight</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.ncb_score.ncb_pico_score_weight}{'%'}</Card.Title>
                                </Card>{ }
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Result</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{(data.calculated_data.ncb_score.ncb_pico_score_result).toFixed(2)}{'%'}</Card.Title>

                                </Card>
                            </Col>

                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>PD</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.ncb_score.ncb_odds}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='credit_detail' style={{ marginTop: '1.5rem' }}>รายละเอียดบัตรเครคิต</Card.Title>
                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>อายุเครคิต</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.credit_age}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>จำนวนเดือนที่ไม่เสีย</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.undamaged_months}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Company score</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.undamaged_months_pico_score}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Weight</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.undamaged_months_pico_score_weight}{'%'}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Result</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{(data.calculated_data.credit_card_detail.undamaged_months_pico_score_result).toFixed(2)}{'%'}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>อายุบัญชีที่ Active ยาวนานที่สุด</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.longest_active_account}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Company score</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.longest_active_account_pico_score}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Weight</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.longest_active_account_pico_score_weight}{'%'}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Result</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{(data.calculated_data.credit_card_detail.longest_active_account_pico_score_result).toFixed(2)}{'%'}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>บัญชีเสีย 1-3 งวด</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.bad_account_1to3_installments}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดหนี้คงเหลือที่เสีย 1-3 งวด</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.bad_debt_balance_1to3_installments.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดหนี้คงเหลือที่เสีย 4 งวดขึ้นไป</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.bad_debt_balance_more_than_4_installments.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>บัญชีเสีย 4 งวดขึ้นไป</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.bad_account_more_than_4_installments}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Company score</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.bad_account_more_than_4_installments_pico_score}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Weight</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.bad_account_more_than_4_installments_pico_score_weight}{'%'}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Result</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{(data.calculated_data.credit_card_detail.bad_account_more_than_4_installments_pico_score_result).toFixed(2)}{'%'}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>จำนวนบัญชีทั้งหมด</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.total_account.toLocaleString()}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>จำนวนบัญชีที่ Active</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.total_active_account.toLocaleString()}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>จำนวนบัญชีที่ Active (กู้ร่วม)</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_detail.total_active_joint_account.toLocaleString()}</Card.Title>

                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='car_home_detail' style={{ marginTop: '1.5rem' }}>รายละเอียดบ้าน/รถยนต์</Card.Title>
                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>จำนวนสินเชื่อบ้าน</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.home_car_loan_detail.total_home_loan.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดหนี้คงเหลือบ้าน</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.home_car_loan_detail.home_debt.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>จำนวนสินเชื่อรถ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.home_car_loan_detail.total_car_loan.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดหนี้คงเหลือรถ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.home_car_loan_detail.car_debt.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='bad_history' style={{ marginTop: '1.5rem' }}>ประวัติหนี้เสีย</Card.Title>
                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ประวัติเสียกับ NCB</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.bad_history_with_ncb}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Company score</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.bad_history_with_ncb_pico_score}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Weight</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.bad_history_with_ncb_pico_score_weight}{'%'}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>Result</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{(data.calculated_data.bad_debt_history.bad_history_with_ncb_pico_score_result).toFixed(2)}{'%'}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>วันที่เสียครั้งแรก</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.first_owed}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ช่วงเวลาที่เริ่มแก้ไข</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.started_edit}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>แก้ไขเสร็จสิ้นเมื่อไร</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.finished_edit}</Card.Title>

                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ปล่อยเสียมากี่เดือน</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.total_left_months.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='open_account_in_1_year' style={{ marginTop: '1.5rem' }}>บัญชีที่เปิดภายใน 1 ปี</Card.Title>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '9%' }}><h6>ลำดับบัญชี</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>วันที่เปิดบัญชี</h6></th>
                                    <th style={{ textAlign: 'center' }}><h6>ประเภทบัญชี</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>วงเงิน</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>ผ่อนชำระ</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>ภาระหนี้คงเหลือ</h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(data.calculated_data.open_account_in_1_year.rows).map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>{value.index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{value.dateAccountOpened.slice(0, 10)}</td>
                                            <td style={{ textAlign: 'center' }}>{value.accountType.toLocaleString()}</td>
                                            <td style={{ textAlign: 'center' }}>{value.creditLimit.toLocaleString()}{'.00'}</td>
                                            <td style={{ textAlign: 'center' }}>{value.installmentAmount.toLocaleString()}{'.00'}</td>
                                            <td style={{ textAlign: 'center' }}>{value.amountOwed.toLocaleString()}{'.00'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr className='total-table'>
                                    <td colSpan={3} style={{ textAlign: 'center' }}>รวม</td>
                                    <td style={{ textAlign: 'center' }}>{(data.calculated_data.open_account_in_1_year.footer.creditLimt).toLocaleString()}{'.00'}</td>
                                    <td style={{ textAlign: 'center' }}>{(data.calculated_data.open_account_in_1_year.footer.installmentAmount).toLocaleString()}{'.00'}</td>
                                    <td style={{ textAlign: 'center' }}>{(data.calculated_data.open_account_in_1_year.footer.amountOwed).toLocaleString()}{'.00'}</td>
                                </tr>
                            </tfoot>
                        </Table>

                        <Card.Title as='h3' name='close_account_in_1_year' style={{ marginTop: '1.5rem' }}>บัญชีที่ปิดภายใน 1 ปี</Card.Title>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '9%' }}><h6>ลำดับบัญชี</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>วันที่ปิดบัญชี</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>วันที่เปิดบัญชี</h6></th>
                                    <th style={{ textAlign: 'center' }}><h6>ประเภทบัญชี</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>วงเงิน</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>ผ่อนชำระ</h6></th>
                                    <th style={{ textAlign: 'center', width: '12%' }}><h6>ภาระหนี้คงเหลือ</h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(data.calculated_data.close_account_in_1_year.rows).map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>{value.index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{value.dataAccountClosed.slice(0, 10)}</td>
                                            <td style={{ textAlign: 'center' }}>{value.dateAccountOpened.toLocaleString()}</td>
                                            <td style={{ textAlign: 'center' }}>{value.accountType.toLocaleString()}</td>
                                            <td style={{ textAlign: 'center' }}>{value.creditLimit.toLocaleString()}{'.00'}</td>
                                            <td style={{ textAlign: 'center' }}>{value.installmentAmount.toLocaleString()}{'.00'}</td>
                                            <td style={{ textAlign: 'center' }}>{value.amountOwed.toLocaleString()}{'.00'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr className='total-table'>
                                    <td colSpan={4} style={{ textAlign: 'center' }}>รวม</td>
                                    <td style={{ textAlign: 'center' }}>{data.calculated_data.close_account_in_1_year.footer.creditLimt.toLocaleString()}{'.00'}</td>
                                    <td style={{ textAlign: 'center' }}>{data.calculated_data.close_account_in_1_year.footer.installmentAmount.toLocaleString()}{'.00'}</td>
                                    <td style={{ textAlign: 'center' }}>{data.calculated_data.close_account_in_1_year.footer.amountOwed.toLocaleString()}{'.00'}</td>
                                </tr>
                            </tfoot>
                        </Table>

                        <Row>
                            <Col className="col-6 col-sm-4">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดส่วนต่างระหว่างบัญชีเปิด-ปิด</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.difference.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='enquiry' style={{ marginTop: '1.5rem' }}>ประวัติสืบค้น NCB</Card.Title>
                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ขอสินเชื่อใหม่ (3 เดือน)</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.enquiry_ncb_history.req_new_loan_3}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ขอสินเชื่อใหม่ (2 เดือน)</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.enquiry_ncb_history.req_new_loan_2}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ขอสินเชื่อใหม่ (1 เดือน)</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.enquiry_ncb_history.req_new_loan_1}</Card.Title>
                                </Card>
                            </Col>
                        </Row>


                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ทบทวนสินเชื่อ (3 เดือน)</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.enquiry_ncb_history.revive_loan_3}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ทบทวนสินเชื่อ (2 เดือน)</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.enquiry_ncb_history.revive_loan_2}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ทบทวนสินเชื่อ (1 เดือน)</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.enquiry_ncb_history.revive_loan_1}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='other' style={{ marginTop: '1.5rem' }}>ข้อมูลอื่นๆ</Card.Title>
                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>วันที่ผิดนัดชำระ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.other_detail.defaultDate}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>วันที่ชำระหนี้ล่าสุด</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.other_detail.dateOflastPayment}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดหนี้คงเหลือ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.bad_debt_history.amountOwed.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดหนี้ที่เกินกำหนดชำระ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.other_detail.amountPastDue.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='credit_card' style={{ marginTop: '1.5rem' }}>ข้อมูลกลุ่มบัญชีบัตรเครคิต</Card.Title>
                        <Row>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>จำนวนสินเชื่อหมุนเวียน</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_information.amount.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>วงเงินหมุนเวียนทั้งหมด</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_information.total_revolving.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>วงเงินที่ใช้ไป</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_information.total_spent.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>ยอดจ่ายขึ้นต่ำ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_information.low_payout.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>หมุนเวียนคงเหลือ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_information.revolving_current.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>วงเงินที่เบิกใช้ได้</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_information.amount_can_withdrawn.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>จำนวนบัญชีที่ Active</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_information.total_active_account.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                            <Col className="col-6 col-sm-3">
                                <Card style={{ margin: '0.7rem' }}>
                                    <Card.Title style={{ margin: '0.7rem' }} as='h6'>วงเงินที่เบิกใช้ได้หลังจ่ายขึ้นต่ำ</Card.Title>
                                    <Card.Title style={{ marginRight: '1rem', textAlign: 'right' }} as='h3'>{data.calculated_data.credit_card_information.amount_can_withdrawn_after_deduction.toLocaleString()}</Card.Title>
                                </Card>
                            </Col>
                        </Row>

                        <Card.Title as='h3' name='loan_detail' style={{ marginTop: '1.5rem' }}>ข้อมูลสินเชื่อ (ย้อนหลัง 3 เดือน)</Card.Title>
                        <Table hover >
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '20%' }}><h6>วัน/เวลา</h6></th>
                                    <th style={{ textAlign: 'center', width: '25%' }}><h6>ผู้ให้สินเชื่อ</h6></th>
                                    <th style={{ textAlign: 'center', width: '25%' }}><h6>ประเภทสถาบันการเงิน</h6></th>
                                    <th style={{ textAlign: 'center', width: '25%' }}><h6>วัตถุประสงค์การสืบค้น</h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(data.calculated_data.loan_information_last_3_months.rows).map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>{value.date}</td>
                                            <td style={{ textAlign: 'center' }}>{value.member}</td>
                                            <td style={{ textAlign: 'center' }}>{value.type_of_financial}</td>
                                            <td style={{ textAlign: 'center' }}>{value.purpose}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>

                        <Card style={{ marginTop: '5rem' }}>
                            {Object.values(data.data.account).map((value, index) => {
                                return (
                                    <div key={index}>
                                        <ListGroup.Item>
                                            <Card.Header>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '12rem' }}>ข้อมูลบัญชี {index + 1}</th>
                                                            <th className='small-text' style={{ width: '35rem' }}>วงเงินสินเชื่อ/วงเงินบัตรเครคิตรวม: {parseInt(value.creditLimit).toLocaleString()}</th>
                                                            <th className='small-text' style={{ width: '35rem' }}>ยอดหนี้คงเหลือ/ยอดวงเงินที่ใช้รวม: {parseInt(value.amountOwed).toLocaleString()}</th>
                                                            <th className='small-text' style={{ width: '25rem' }}>ยอดหนี้ที่เกินกำหนด: {parseInt(value.amountPastDue).toLocaleString()}</th>
                                                            <th className='small-text' style={{ width: '8rem' }}><Button variant="primary" onClick={() => handleClick(index)} onDoubleClick={() => handleDoubleClick()}>รายละเอียด</Button></th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </Card.Header>
                                        </ListGroup.Item>

                                        <ListGroup.Item id={index} style={{ display: isShown ? 'block' : 'none' }}>
                                            {/* style={{display: isShown ? 'block' : 'none'}} */}
                                            <Row >
                                                <Col style={{ paddingTop: '2rem', paddingLeft: '4rem' }}>
                                                    <p className='small-text' style={{ fontWeight: 'bold' }}>ผู้ให้สินเชื่อ</p>

                                                    <p className='small-text'>
                                                        ผู้ให้สินเชื่อ:&emsp;{value.memberShortName}<br />
                                                        ประเภทสมาชิก:<br />
                                                        ข้อมูล ณ วันที่:&emsp;{value.asOfDate}<br />
                                                    </p>
                                                </Col>
                                                <Col style={{ padding: '2rem' }}>
                                                    <p className='small-text' style={{ fontWeight: 'bold' }}>บัญชี</p>
                                                    <p className='small-text'>
                                                        บัญชี:<br />
                                                        ประเภทสินเชื่อ:&emsp;{convert_account_type(value.accountType)}<br />
                                                        เปิดบัญชี:&emsp;{value.dateAccountOpened}<br />
                                                        วันที่ปรับปรุงโครงสร้างหนี้:&emsp;{value.dateOfLastDebtRestructure}<br />
                                                        บัญชีที่ปิด:&emsp;{value.dateAccountClosed}<br />
                                                    </p>
                                                </Col>
                                                <Col style={{ padding: '2rem' }}>
                                                    <p className='small-text' style={{ fontWeight: 'bold' }}>รายละเอียด</p>
                                                    <p className='small-text'>
                                                        ประเภทของบัตรเครคิต: <br />
                                                        ลักษณะการกู้:&ensp;{convert_ownershipIndicator_type(value.ownershipIndicator)}<br />
                                                        จำนวนผู้กู้ร่วม:&emsp;{value.numberOfCoborrowers}<br />
                                                        วงเงินสินเชื่อ/วงเงินบัตรเครคิต:&emsp;{parseInt(value.creditLimit).toLocaleString()} {(value.creditTypeFlag === undefined) ? null : '(' + value.creditTypeFlag + ')'}<br />
                                                        ยอดผ่อนชำระในแต่ละงวด/ยอดชำระในแต่ละงวด:&emsp;{'THB'}&ensp;{parseInt(value.installmentAmount).toLocaleString()}<br />
                                                        {value.installmentFrequency === '9' ? 'รายปี' : 'รายเดือน'}<br />
                                                        จำนวนงวดที่ต้องผ่อนชำระ:&emsp;{value.installmentNumberOfPayment}<br />
                                                        ยอดขั้นตำ่ที่ต้องผ่อนชำระ(%):&emsp;{value.percentPayment}{'%'}<br />
                                                        ยี่ห้อ:&emsp;{value.unitMake}<br />
                                                        รุ่น:&emsp;{value.unitModel}<br />
                                                        หลักประกัน:<br />
                                                        {value.collateral1 === undefined ? undefined : <span>1:&emsp;{value.collateral1}</span>}<br />
                                                        {value.collateral2 === undefined ? undefined : <span>2:&emsp;{value.collateral2}</span>}<br />
                                                        {value.collateral3 === undefined ? undefined : <span>3:&emsp;{value.collateral3}</span>}
                                                    </p>
                                                </Col>
                                                <Col style={{ padding: '2rem' }}>
                                                    <p className='small-text' style={{ fontWeight: 'bold' }}>ยอดหนี้คงเหลือ/ยอดวงเงินที่ใช้</p>
                                                    <p className='small-text'>
                                                        ยอดหนี้คงเหลือ/ยอดวงเงินที่ใช้:&emsp;<br />{'THB'}&ensp;{parseInt(value.amountOwed).toLocaleString()}<br />
                                                        ยอดหนี้ที่เกินกำหนดชำระ:&emsp;{parseInt(value.amountPastDue).toLocaleString()}<br />
                                                        วันที่ชำระหนี้ล่าสุด:&emsp;{value.dateOflastPayment}<br />
                                                        วันที่เริ่มมีการผิดนัดชำระ:&emsp;<br />{value.defaultDate}<br />
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '-2rem' }}>
                                                <Col sm={3}>
                                                    <h5 style={{ marginLeft: '2rem', fontWeight: 'bold' }}>ข้อมูลการค้างชำระ (36 เดือน)</h5>
                                                    <Row>
                                                        <Col style={{ marginLeft: '2rem', textAlign: 'center' }}>{'>'}30 วัน: {data.calculated_data.payment_history.payment_history_detail3[index][0]}</Col>
                                                        <Col style={{ marginLeft: '2rem', textAlign: 'center' }}>{'>'}60 วัน: {data.calculated_data.payment_history.payment_history_detail3[index][1]}</Col>
                                                        <Col style={{ marginLeft: '2rem', textAlign: 'center' }}>{'>'}90 วัน: {data.calculated_data.payment_history.payment_history_detail3[index][2]}</Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 style={{ marginLeft: '2rem', fontWeight: 'bold' }}>ประวัติการชำระ (36 เดือน)</h5>
                                                    <p style={{ marginLeft: '2rem' }}>
                                                        {Object.values(data.calculated_data.payment_history.payment_history_detail1[index][1]).map((value, index) => {
                                                            return <span key={index} style={{ paddingLeft: '3px' }}>{icon(value[0])}</span>
                                                        })}
                                                    </p>
                                                    <p style={{ marginLeft: '2rem', marginTop: '-1rem' }} >
                                                        {Object.values(data.calculated_data.payment_history.payment_history_detail2[index]).map((value, index) => {
                                                            return <span key={index} className='small-icon' style={{ paddingLeft: '6.5px' }}>&nbsp;{value}</span>
                                                        })}
                                                    </p>
                                                </Col>
                                            </Row>

                                            <h5 style={{ marginLeft: '2rem', marginTop: '-0.5rem', fontWeight: 'bold' }}>สถานะ</h5>
                                            <p style={{ marginLeft: '2rem' }} className='small-text'>สถานะบัญชี:&emsp;{value.accountStatus}: {convert_account_status(value.accountStatus)}</p>
                                            <h5 style={{ marginLeft: '2rem', fontWeight: 'bold' }}>วัตถุประสงค์</h5>
                                            <p style={{ marginLeft: '2rem' }} className='small-text'>วัตถุประสงค์:&emsp;{value.loanObjective}</p>
                                            <h5 style={{ marginLeft: '2rem', fontWeight: 'bold' }}>ประวัติการชำระ</h5>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <Table hover >
                                                        <thead>
                                                            <tr>
                                                                <th className='small-text' style={{ textAlign: 'center', width: '15%' }}>ข้อมูล ณ วันที่</th>
                                                                <th style={{ textAlign: 'center', width: '25%' }}><h6>วงเงินสินเชื่อ/วงเงินบัตรเครคิต</h6></th>
                                                                <th style={{ textAlign: 'center', width: '25%' }}><h6>ลักษณะการชำระ/จำนวนวันค้างชำระ</h6></th>
                                                                <th style={{ textAlign: 'center', width: '25%' }}><h6>ยอดหนี้คงเหลือ/ยอดวงเงินที่ใช้</h6></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.values(data.calculated_data.table_detail).map((table, index) => {
                                                                if (value.segment === table.segment) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                {table.asOfDate.slice(0, 10)}
                                                                            </td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                {(table.creditLimit === undefined) ? '-' : ('THB  ' + parseInt(table.creditLimit).toLocaleString())}
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', paddingLeft: '1.5rem' }}>
                                                                                {convert_type_payment(table.overDueMonths)}
                                                                            </td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                {'THB'}&ensp;{parseInt(table.amountOwed).toLocaleString()}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </ListGroup.Item>
                                    </div>
                                )
                            })}
                        </Card>
                    </Card.Body>
                </Card>
            </Container >
        </>

    )
}