/* eslint-disable eqeqeq */
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

const columns = [
    { id: '', label: 'No', minWidth: 50 },
    { id: 'TransactionDate', label: 'Trans.Date', minWidth: 110 },
    { id: 'Duration', label: 'Duration', minWidth: 110 },
    { id: 'TRC', label: 'Trans.Code', minWidth: 110 },
    { id: 'OSB_Yokma', label: 'ต้นยกมา', minWidth: 110 },
    { id: 'CurrentInteres', label: 'ดอกยกมา', minWidth: 110 },
    { id: 'IntToDate', label: 'ดอกครั้งนี้', minWidth: 110 },
    { id: 'CUMINT', label: 'ดอกรวม', minWidth: 110 },
    { id: 'Plub', label: 'ค่าทวงถามรวม', minWidth: 110 },
    { id: 'Receive', label: 'ชำระเข้า', minWidth: 110 },
    { id: 'ReturnPlub', label: 'คืนค่าทวงถาม', minWidth: 110 },
    { id: 'ReturnInteres', label: 'คืนดอก', minWidth: 110 },
    { id: 'ReturnPrinciple', label: 'คืนต้น', minWidth: 110 },
    { id: 'PlubCUM', label: 'ค่าทวงถามค้าง', minWidth: 110 },
    { id: 'TTINT', label: 'ดอกค้าง', minWidth: 110 },
    { id: 'OSB', label: 'ต้นค้าง', minWidth: 110 },
    { id: 'รวมค้าง', label: 'รวมค้าง', minWidth: 110 },
    { id: 'หนี้ค้าง', label: 'หนี้ค้าง', minWidth: 110 },
];

export default function StickyHeadTable({ data }) {
    const rows = data
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    let duration = 0
    let intToDate = 0
    let receive = 0
    let returnInteres = 0
    let returnPrinciple = 0

    function financial(n) {
        if (n == 0) {
            return "-"
        } else if (n == null || n == undefined) {
            return "-"
        } else {
            return n?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
    }

    const total = () => {
        data.map((row) => {
            duration += row.Duration
            intToDate += row.IntToDate
            receive += row.Receive
            returnInteres += row.ReturnInteres
            returnPrinciple += row.ReturnPrinciple
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {total()}
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                let index = rows.findIndex(obj => obj.id === row.id)

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {/* {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })} */}
                                        <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.TransactionDate.split(" ")[0]}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{row.Duration}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{row.TRC}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{financial(row.OSB_Yokma)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.CurrentInteres)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.IntToDate)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.CUMINT)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.Plub)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.Receive)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.ReturnPlub)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.ReturnInteres)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.ReturnPrinciple)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.PlubCUM)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.TTINT)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.OSB)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{financial(row.TTINT + row.OSB)}</TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>{row.IntToDate === "ADJ INT" ? financial(row.IntToDate) : "-"}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{ textAlign: 'right' }}>{duration}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{ textAlign: 'right' }}>{financial(intToDate)}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{ textAlign: 'right' }}>{financial(receive)}</TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{ textAlign: 'right' }}>{financial(returnInteres)}</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>{financial(returnPrinciple)}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[100, 300, 500]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
