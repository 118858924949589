import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as userHttpServices from '../../src/services/services';

// import {useSelector, useDispatch} from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'
import {
    Card,
} from 'react-bootstrap';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                {/* Your Website */}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    let myobj = {
        "username": username,
        "password": password
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        userHttpServices.login(myobj)
            .then((res) => {
                console.log(res)
                if (res.data.success) {
                    localStorage.setItem('token', res.data.token)
                    alert('login success')
                    window.location.href = "/ncb/search";
                } else {
                    alert('login failed')
                }
            })
    };

    // const test = useSelector((state) => state.test1Slice.value)

    return (
        <>
            <ThemeProvider theme={theme}>
                <Card style={{ marginTop: '8rem', marginRight: '35%', marginLeft: '35%' }} className="shadow p-3 mb-5 bg-white rounded">
                    <Container component="main" maxWidth="xs">

                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography
                                component="h1"
                                variant="h5"
                                className={'small-text'}
                            >
                                เข้าสู่ระบบ
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Username"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={(e) => setPassword(e.target.value)}

                                />
                                {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}

                                <Button
                                    type="submit"
                                    id="my-btn"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    style={{ backgroundColor: "#7848f9" }}
                                >
                                    เข้าสู่ระบบ
                                </Button>
                                <Grid container>
                                    {/* <Grid item xs>
                                    <Link href="#" variant="body2" >
                                        ลืมรหัสผ่าน?
                                    </Link>
                                </Grid> */}

                                </Grid>
                            </Box>
                        </Box>
                        <Copyright sx={{ mt: 8, mb: 4 }} />

                    </Container>
                </Card>

            </ThemeProvider>
        </>

    );
}