import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import UploadStatement from '../../components/statement/UploadStatement';


export default function TestUploadStatement() {
  return (
    <div style={{ margin: '2rem' }}>
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs={12}>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>Upload statement</Typography>
        </Grid>
        <Grid item xs={12} >
            <UploadStatement />
        </Grid>
      </Grid>
    </div>
  )
}