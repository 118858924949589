import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const columns = [
    { id: 'date', label: '#', minWidth: 200 },
    {
        id: 'paid',
        label: 'ชำระแล้ว',
        minWidth: 150,
        align: 'center',
    },
    {
        id: 'cantcontact',
        label: 'ติดต่อไม่ได้',
        minWidth: 150,
        align: 'center',
    },
    {
        id: 'appointment',
        label: 'นัดชำระ',
        minWidth: 150,
        align: 'center',
    },
    {
        id: 'debt',
        label: 'หนี้สูญทางบัญชี',
        minWidth: 150,
        align: 'center',
    },
    {
        id: 'noappointment',
        label: 'ไม่นัดชำระ',
        minWidth: 150,
        align: 'center',
    },
    {
        id: 'nofollowing',
        label: 'ไม่ได้ติดตาม',
        minWidth: 150,
        align: 'center',
    },
    {
        id: 'total',
        label: 'ลูกหนี้ทั้งหมด',
        minWidth: 150,
        align: 'center',
    },
];

function createData(date, paid, cantcontact, appointment, debt, noappointment, nofollowing, total) {
    return { date, paid, cantcontact, appointment, debt, noappointment, nofollowing, total };
}

const rows = [
    createData('17-23/11/2022', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)'),
    createData('9-16/11/2022', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)'),
    createData('1-8/11/2022', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)', '0 (0.0%)'),
];

export default function StickyHeadTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography m={2} sx={{ fontWeight: 'bold', fontSize: 22 }}>สรุปผลการติดตาม รอบสัปดาห์ที่ 17 - 23/11/2022</Typography>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}