/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import './../sum.css'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SideBar from './../../page/dashboard'
import TableData from '../../components/table/table_overall_report';
import BarChart from './../../../src/page/test/TestBarChart';

import PieChart from './../../../src/page/test/TestPieChart';

import InfoIcon from '@mui/icons-material/Info';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
// import Table from 'react-bootstrap/Table';
// import { useParams } from "react-router-dom";
// import * as userHttpServices from '../../../src/services/services';

const style = {
  height: '100%',
  boxShadow: 1,
  borderRadius: 1,
};



export default function App() {
  const [date, setDate] = React.useState(null);
  const [type, setType] = React.useState(10);

  return (
    // backgroundColor: '#FBFBFB'
    <div className='Container' style={{ margin: '2rem' }} >
      <SideBar />

      <Box mt={7} mb={3} sx={{
        width: '100%'
      }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography variant='h4'>Collector Performance Overall Report</Typography>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <Typography variant='h6'>Selected date</Typography>

            <Box ml={2} sx={{ width: 200 }} >
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DesktopDatePicker
                  label="Date"
                  inputFormat="YYYY-MM-DD"
                  value={date}
                  onChange={(date) => setDate(date)}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </LocalizationProvider>
            </Box>

            <Box ml={2} >
              <FormControl sx={{ width: 200 }} size="small">
                <InputLabel id="demo-simple-select-label">Accumlate by</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={type}
                  label="Accumlate by"
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={'Weekly'}>Weekly</MenuItem>
                  <MenuItem value={'Monthly'}>Monthly</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box ml={2} >
              <Button variant="contained" size="median">Search</Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={3}>

              <Grid item xs={6} md={8}>
                <List sx={style} component="nav" aria-label="mailbox folders">
                  <ListItem >
                    <Box m={2} sx={{
                      width: '100%'
                    }}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 20 }} color="text.secondary">สรุปผลการติดตาม</Typography>
                        <InfoIcon color='disabled' fontSize='large' />
                      </Box>

                      <Typography sx={{ fontWeight: 'light', fontSize: 18 }}>รอบสัปดาห์ที่ 17 - 23/11/2022</Typography>
                    </Box>
                  </ListItem>
                  <Divider />
                  <Divider />
                  <Divider />
                  <Divider />

                  <ListItem>
                    <BarChart />
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={6} md={4}>
                <List sx={style} component="nav" aria-label="mailbox folders">
                  <ListItem >
                    <Box m={2} sx={{
                      width: '100%'
                    }}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 20 }} color="text.secondary">จำนวนเงินที่ได้รับ</Typography>
                        <InfoIcon color='disabled' fontSize='large' />
                      </Box>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 36, color: '#121A59' }}>5,987.34</Typography>
                      <Typography sx={{ fontWeight: 'light', fontSize: 18 }}>รอบสัปดาห์ที่ 17 - 23/11/2022</Typography>

                    </Box>
                  </ListItem>
                  <Divider />
                  <Divider />
                  <Divider />
                  <Divider />

                  <ListItem sx={{ pl: 5 }}>
                    <PieChart />
                  </ListItem>
                </List>
              </Grid>

            </Grid>


          </Grid>
          <Grid item xs={12} md={12}>
            <TableData />
          </Grid>
        </Grid>
      </Box>

    </div >
  )
}