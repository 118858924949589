import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { read, utils, WorkSheet } from 'xlsx';

import {
  createStatement
} from '../../services/statement'

const UploadStatement = ({ }) => {
  const [bank, setBank] = useState(0);
  const [file_type, setFileType] = useState(1);
  const [data_model, setDataModel] = useState([]);
  const fileInput = useRef(null);

  const handleBankChange = (event) => {
    setBank(event.target.value);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleClick = () => {
    fileInput.current.value = '';
    window.location.reload(false)
  }

  const onFileBodyChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      // handle unselect option 
      // To-do clear file input value when error occure
      if (file_type === 0) {
        alert("Please select file type")
        fileInput.current.value = ''
      } else if (bank === 0) {
        alert("Please select bank")
        fileInput.current.value = ''
      } else if (file_type === 1) {
        let file = event.target.files[0]
        const f = await file.arrayBuffer()
        const wb = read(f) // parse the array buffer
        switch (bank) {
          case 1:
            extractAndTransfromKBank(wb)
            break
          case 2:
            extractAndTransfromTTB(wb)
            break
          default:

            break;
        }
      } else if (file_type === 2) {
        // call picture ORC services
      }
    }
  }

  const extractRowsAndColumns = (work_sheet) => {
    const rows = utils.sheet_to_json(work_sheet, { header: 1 });

    /* column objects are generated based on the worksheet range */
    const range = utils.decode_range(work_sheet["!ref"] || "A1");
    const columns = Array.from({ length: range.e.c + 1 }, (_, i) => ({
      /* for an array of arrays, the keys are "0", "1", "2", ... */
      key: String(i),
      /* column labels: encode_col translates 0 -> "A", 1 -> "B", 2 -> "C", ... */
      name: utils.encode_col(i)
    }));
    return rows
  }

  const extractAndTransfromTTB = (excel_object) => {
    var account_info = {}
    let account_summarize = []
    let account_statement = []

    // let ws = excel_object.Sheets["Table 7"]
    // let rows = utils.sheet_to_json(ws, { header: 1 });
    // console.log(rows);


    for (let i = 3; i < excel_object.SheetNames.length; i++) {
      let ws = excel_object.Sheets[excel_object.SheetNames[i]]
      let rows = utils.sheet_to_json(ws, { header: 1 });
      // console.log(rows[0][0] );
      if (i === 3) {
        account_info["account_name"] = rows[0][0].split('\n')[1]
      } else if (i == 4) {
        account_info["account_number"] = rows[0][1]
        account_info["account_period"] = rows[1][1]
        account_info["account_branch"] = rows[2][1]
        account_info["account_info_1"] = ""
        account_info["account_ref"] = ""
        account_info["account_address"] = {}
        account_info["account_statment"] = {}
      } else if (rows[0][0] === "วันที˙") {
        // console.log(rows);
        let month_in_thai = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "	พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]
        for (let j = 1; j < rows[j].length; j++) {
          if (rows[j].length > 0) {
            let day = parseInt(rows[j][0].split(' ')[0])
            let month = rows[j][0].split(' ')[1]
            let year = parseInt(rows[j][0].split(' ')[2])
            let month_date = month_in_thai.indexOf(month)
            let deposit = 0
            let withdraw = 0
            if (rows[j][4] > 0) {
              deposit = rows[j][4]
            } else {
              withdraw = rows[j][4] * -1
            }
            let statement_transaction = {
              "date": new Date(year + 2500 - 543, month_date, day),
              "time": rows[j][1].split(" ")[0],
              "title": rows[j][2],
              "deposite": deposit,
              "withdraw": withdraw,
              "balance": rows[j][5],
              "channel": rows[j][3],
              "description": rows[j][6]
            }
            account_statement.push(statement_transaction)
          }
        }
        // break
      }
    }
    // console.log(account_info);
    let statement_object = {
      "contact_id": "7999051",
      "bank": "ธ.ทหารไทยธนชาต",
      "statement": {
        "account_info": account_info,
        "account_statment": account_statement
      }
    }
    console.log(statement_object);
    createStatement(statement_object).then(res => {
      alert("Upload success")
    })
  }

  const extractAndTransfromKBank = (excel_object) => {
    var account_info = {}
    let account_summarize = []
    let account_statement = []
    // const ws = excel_object.Sheets[excel_object.SheetNames[0]]
    // let excel_array = extractRowsAndColumns(ws)
    for (let i = 0; i < excel_object.SheetNames.length; i++) {
      // for(let i = 0; i<5; i++){
      let ws = excel_object.Sheets[excel_object.SheetNames[i]]
      let rows = utils.sheet_to_json(ws, { header: 1 });
      // console.log(rows);
      if (i === 0) {
        // extract first page
        // console.log(rows[0]);
        console.log(rows[0][0].split("\n")[2]);
        account_info["account_info_1"] = rows[0][0].split("\n")[0].split(":")[1]
        account_info["account_name"] = rows[0][0].split("\n")[1]
        account_info["account_address"] = [
          rows[0][0].split("\n")[2]
        ]
      } else if (i === 1) {
        // extract secound page
        account_info["account_ref"] = rows[0][1]
        account_info["account_number"] = rows[1][1]
        account_info["account_period"] = rows[2][1]
        account_info["account_branch"] = rows[3][1]
        account_summarize = {
          "account_total_balance": parseFloat(rows[4][0].split(" ")[rows[4][0].split(" ").length - 1]),
          "account_total_withdraw_number": parseFloat(rows[5][0].split(" ")[1]),
          "account_total_deposite_number": parseFloat(rows[6][0].split(" ")[1]),
          "account_total_withdraw": parseFloat(rows[5][0].split(" ")[rows[5][0].split(" ").length - 1].replace(/,/g, '')),
          "account_total_deposite": parseFloat(rows[6][0].split(" ")[rows[6][0].split(" ").length - 1].replace(/,/g, ''))
        }
      } else if (rows.length > 10) {
        let balance = rows[2][4];
        // console.log(balance);
        for (let j = 3; j < rows.length; j++) {
          if (rows[j][2] !== undefined && rows[j][2] !== "ยอดยกมา") {
            // console.log(rows[j][0]);
            let amount = rows[j][3]
            let new_balance = rows[j][4]
            let deposit = 0
            let withdraw = 0
            if (new_balance > balance) {
              deposit = amount
            } else {
              withdraw = amount
            }
            let statement_transaction = {
              "date": new Date(Math.round((rows[j][0] - 25569) * 86400 * 1000)),
              "time": rows[j][1],
              "title": rows[j][2],
              "deposite": deposit,
              "withdraw": withdraw,
              "balance": new_balance,
              "channel": rows[j][5],
              "description": rows[j][6]
            }
            balance = new_balance
            account_statement.push(statement_transaction)
          }
        }
        // console.log(account_statement);
        // break
      }
      // console.log(account_info);
      // if(rows[0])
      // break
    }
    account_info["account_summary"] = account_summarize
    let statement_object = {
      "contact_id": "7999051",
      "bank": "ธ.กสิกรไทย",
      "statement": {
        "account_info": account_info,
        "account_statment": account_statement
      }
    }
    console.log(statement_object);
    createStatement(statement_object).then(res => {
      alert("Upload success")
    })
    // let statement_object = {
    //   "account_info"
    // }
  }

  return (
    <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={bank}
              onChange={handleBankChange}
              fullWidth
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
            >
              <MenuItem key={0} value={0}>กรุณาเลือกธนาคาร</MenuItem>
              <MenuItem value={1}>ธ.กสิกรไทย</MenuItem>
              <MenuItem value={2}>ธ.ทหารไทยธนชาต</MenuItem>
              {/* <MenuItem value={3}>ธ.กรุงเทพ</MenuItem> */}
            </Select>
          </Grid>
          {/* <Grid item xs={4}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={file_type}
              onChange={handleFileTypeChange}
              fullWidth
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
            >
              <MenuItem key={0} value={0}>กรุณาเลือกประเภทไฟล์</MenuItem>
              <MenuItem value={1}>excel</MenuItem>
              <MenuItem value={2}>picture</MenuItem>
              {/* <MenuItem value={3}></MenuItem> 
            </Select>
          </Grid> */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="ไฟล์ Statement"
              // value={news_thumbnail} 
              onChange={event => onFileBodyChange(event)}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                backgroundColor: "#FFFFFF"
              }}
              type="file"
              ref={fileInput}
              inputProps={{ accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item ></Grid>
          <Grid item>
            {/* <Button variant="contained" color="success" onClick={}>{button_success}</Button> */}
          </Grid>
          <Grid item>
            <Button variant="contained" color="error" onClick={handleClick} >CLOSE</Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
export default UploadStatement;
