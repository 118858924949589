import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 0,
}

const test1Slice = createSlice({
    name: 'test',
    initialState,
    reducers: {

    }
})


export const {} = test1Slice.actions;
// export const test1Selector = (store: RootState) => store.test1Reducer
export default test1Slice.reducer;