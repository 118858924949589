import 'bootstrap/dist/css/bootstrap.min.css';
import TableData from '../../components/table/table_contact_collector.js';
import './../sum.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SideBar from './../../page/dashboard'
import jwt_decode from "jwt-decode";

export default function App() {

    var token = localStorage.getItem('token')
    var decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    return (
        <div className='contact-management' style={{ margin: '2rem' }}>
            {console.log('>>>',decoded)}
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>รายการทวงหนี้ของ {decoded.name}</Typography>
                </Grid>
            </Grid>

            <TableData />
        </div>
    )
}