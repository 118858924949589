import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import jwt_decode from "jwt-decode";

import {
    getCollectorExport
} from '../../services/collector'

const CollectorExportModal = ({ is_open, handleClose, token }) => {
    const [start_date, setStartDate] = React.useState("")
    const [end_date, setEndDate] = React.useState("")
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob(["\uFEFF"+data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const handleExportButton = () => {
        // console.log(start_date);
        if (start_date > end_date) {
            alert("คุณเลือกวันที่เริ่ม Export มากกว่าวันที่สิ้นสุดการ Export \n กรุณาเลือกใหม่อีกครั้ง")
        } else {
            let decoded = jwt_decode(token)
            console.log(decoded);
            let object = {
                "org_id": decoded.org_id,
                "start_date": start_date,
                "end_date": end_date
            }
            getCollectorExport(token, object).then(res => {
                // console.log(res);
                let header = [
                    '',
                    'สถานะบัญชี (เปิด/ปิด)',
                    'Contract ID (เลขที่สัญญา)',
                    'ชื่อผู้กู้',
                    'รายได้',
                    'Effective Interest Rate',
                    'ประเภทสินเชื่อ',
                    'Pico Score',
                    'วันที่สร้างสัญญา',
                    '\n'
                ]
                let datas = res.data.data
                console.log(datas);
                let export_data = []
                datas.map(data => {
                    let row_data = [
                        data.contract_status,
                        data.contract_no,
                        data.name,
                        data.income,
                        data.interest_rate,
                        data.type,
                        data.score,
                        data.createdate,
                        '\n'
                    ]
                    export_data.push(row_data)
                })
                console.log(export_data);
                downloadFile({
                    data: [header, export_data],
                    fileName: 'export data.csv',
                    fileType: 'text/csv;charset=utf-8',
                })
            })
        }
    }
    return (
        <Modal
            open={is_open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {/* <Box sx={style}> */}
            <Card sx={style}>
                <CardContent>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Export Data
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                id="date"
                                label="start export date"
                                type="date"
                                onChange={(e) => setStartDate(e.target.value)}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="date"
                                label="end export date"
                                type="date"
                                onChange={(e) => setEndDate(e.target.value)}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        onClick={handleExportButton}
                    >
                        Export
                    </Button>
                </CardActions>
            </Card>
            {/* </Box> */}
        </Modal>
    )
}
export default CollectorExportModal;