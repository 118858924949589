import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import * as userHttpServices from '../../../src/services/login_service';

const theme = createTheme();

export default function SignIn() {
  const [org, setOrg] = React.useState("");

  let data = JSON.parse(localStorage.getItem('data'))

  if (data === null) {
    window.location.href = '/login'
  }

  let object = {
    "Username": data.signIn.Username,
    "Password": data.signIn.Password,
    "user_id": data.user_id,
    "org_id": org
  }

  const handleChange = (event) => {
    setOrg(event.target.value);
  };

  const handleSubmit = () => {
    if (org === "") {
      alert("You don't have access to this org in TFM")
    } else {
      userHttpServices.LoginOrg(object)
        .then((res) => {
          console.log(res)
          let token = res.data.data[0].accessToken
          let menus = res.data.data[0].menus
          let role = res.data.data[0].role_id

          localStorage.setItem('menus', JSON.stringify(menus))
          localStorage.setItem('token', token);

          if (role === 1) {
            window.location.href = "/contact_management";
          } else if (role === 2) {
            window.location.href = "/contact_collector";
          } else {
            alert('Login failed - err')
          }
        })
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 2, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="inherit">
            Welcome!
          </Typography>

          <Typography component="h1" variant="subtitle1" align="center" marginTop={3}>
            You don't have access to this org in TFM. Select another org, or contact your admin
            for more details.
          </Typography>
          <FormControl sx={{ mt: 3, minWidth: 350 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Organization</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={org}
              onChange={handleChange}
              label="Organization"
            >
              {data.org_list.map((list) =>
                <MenuItem key={list.org_id} value={list.org_id}>{list.org_name}</MenuItem>
              )}
            </Select>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 4, mb: 2 }}
            style={{ maxWidth: '200px', minWidth: '200px' }}
            onClick={handleSubmit}
          >
            continue
          </Button>

        </Box>
      </Container>
    </ThemeProvider>
  );
}