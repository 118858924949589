import axios from "axios";

export const TFMLogin = (body) => {
    return axios.post(`https://apiv2-uat.transfinmational.net/login`, body, {
    })
}

export const LoginOrg = (body) => {
    return axios.post(`https://apiv2-uat.transfinmational.net/generateToken`, body, {
    })
}