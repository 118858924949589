/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './../../page/dashboard'
import './../sum.css'
import Button from '@mui/material/Button';
import { Card, Container } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { Await, useParams } from "react-router-dom";
import * as userHttpServices from '../../../src/services/user_management_service';
import List from '@mui/material/List';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import { configConsumerProps } from 'antd/lib/config-provider';
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function App() {
  const { state } = useLocation()
  const [role_id, setRoleID] = useState(state.role_id)
  const [role_name, setRoleName] = useState('');
  const [role_desc, setRoleDesc] = useState('');
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    userHttpServices.getRoleDetail(role_id,getToken()).then((res) => {
      console.log(res.data.data)
      setRoleName(res.data.data[0].role_name)
      setRoleDesc(res.data.data[0].role_desc)
    })
    userHttpServices.getUsers(getToken()).then((res) => {
        setUsers(res.data.data)
        let selected = []
        let option = []
        const select = res.data.data.filter((role) => role.role_id == role_id)
        select.map(select => {
          selected.push({role_id:select.role_id,role_name:select.role_name,user_id:select.user_id,user_name:select.Name})
        })
        setRight(selected)
        const unselect = res.data.data.filter((role) => role.role_id != role_id)
        unselect.map(select => {
          option.push({role_id:select.role_id,role_name:select.role_name,user_id:select.user_id,user_name:select.Name})
        })
        setLeft(option)
    })
  }, [])
  const get_user_id = (data) => {
    let user_ids=[]
    data.map(users => {user_ids.push(users.user_id)})
    return user_ids
  } 
  const leftChecked = intersection(checked, get_user_id(left));
  const rightChecked = intersection(checked, get_user_id(right));
  const handleToggle = (value) => () => {
    let user_id= value
    const currentIndex = checked.indexOf(user_id)
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(user_id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked)
  }

  const numberOfChecked = (items) => {
    let user_ids=[]
    items.map(users => {
      user_ids.push(users.user_id)
    })
    let item_length = intersection(checked, user_ids).length
    return item_length
  } 
  const handleToggleAll = (items) => () => {
    let user_ids=[]
    items.map(users => {user_ids.push(users.user_id)})
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, user_ids))
    } else {
      setChecked(union(checked, user_ids))
    }
  };
  const handleCheckedRight = () => {
    let left_select = []
    users.map(users => {
       leftChecked.map(user_id => {
       if(user_id===users.user_id){
         left_select.push({role_id:users.role_id,role_name:users.role_name,user_id:users.user_id,user_name:users.Name})
       }})})
       let left_option  = left.filter(function(array_el){
        return left_select.filter(function(anotherOne_el){
           return anotherOne_el.user_id == array_el.user_id
        }).length == 0
     })
      setLeft(left_option)
    setRight(right.concat(left_select))
    setChecked(not(checked, leftChecked))
  }
  const handleCheckedLeft = () => {
      let right_select = []
    users.map(users => {
      rightChecked.forEach(user_id => {
       if(user_id===users.user_id){
         right_select.push({role_id:users.role_id,role_name:users.role_name,user_id:users.user_id,user_name:users.Name}) 
       }})})
       let right_option  = right.filter(function(array_el){
        return right_select.filter(function(anotherOne_el){
           return anotherOne_el.user_id == array_el.user_id
        }).length == 0
     })
       setRight(right_option)
    setLeft(left.concat(right_select))
    setChecked(not(checked, rightChecked))
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {  items.map((value) => {
          const labelId = `transfer-list-all-item-${value.user_id}-label`;
          return (
            <ListItem
              key={value.user_id}
              role="listitem"
              button
              onClick={handleToggle(value.user_id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.user_id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`Name: ${value.user_name}`} secondary={`Role: ${value.role_name}`}/>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const getToken = () => {
      let token = localStorage.getItem('token')
      return token
  }
  const handleSubmit = (event) => {
    let user_list = []
    right.map((user) => {
      user_list.push(user.user_id)
    })

    event.preventDefault();
    let role_data = {
      role_id:role_id,
      role_name:role_name,
      role_desc: role_desc,
      homepage_uri:'',
      user_list:user_list
  }
    userHttpServices.editRole(role_data, getToken())
      .then((res) => {
        console.log(res)
        if (res.data.response.status) {
          alert('Update Role Success')
        }
      })
      .catch((err) => {
        alert('Update Role Fail')
      })
  }
  return (
    <>
      <Container>
        <div className='header'>
          <Header />
        </div>
        <Grid container style={{ marginTop: '6rem' }}>
          <Grid item xs>
            <Typography variant='h4' style={{ fontWeight: 'bold' }}>Edit User Role</Typography>
          </Grid>
        </Grid>
        <Card style={{ marginTop: '1.5rem', marginRight: '15%', marginLeft: '15%' }} className="shadow p-3 mb-5 bg-white rounded">
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField fullWidth id="roleName" label="Role Name" variant="outlined" value={role_name} onChange={(e) => setRoleName(e.target.value)} />
              </Grid>
              <Grid item xs>
                <TextField fullWidth id="description" label="Description" variant="outlined" value={role_desc} onChange={(e) => setRoleDesc(e.target.value)} />
              </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center" alignItems="center" marginTop={2}>
              <Grid item>{customList('Unassign User Role', left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList('Role' + ' ' + role_name, right)}</Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
              <Grid item xs></Grid>
              <Grid item >
                <Button variant="contained" type='submit' >EDIT</Button>
              </Grid>
              <Grid item >
                <Button variant="contained" color="error" href='/user_role_management'>BACK</Button>
              </Grid>
            </Grid>
          </Box>
        </Card>

      </Container>
    </>
  )
}
