/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../page/header';
import './../sum.css'
import Button from '@mui/material/Button';
import {
    Card,
    Col,
    Row,
    // Button,
    Container,
    // Form,
    Alert,
    ListGroup
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from "@mui/material/Avatar";
import TextField from '@mui/material/TextField';


import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import * as userHttpServices from '../../../src/services/services';

export default function App() {
    const [score, setScore] = useState()
    const { contact_id } = useParams()

    // const getToken = () => {
    //     let token = localStorage.getItem('token')
    //     return token
    // }

    useEffect(() => {
        userHttpServices.getScore()
            .then((res) => {
                console.log(res)
                setScore(res.data.score)
            })
    }, [])


    if (score === undefined) {
        return <div style={{ margin: '2rem' }}>Loading Score...</div>;
    }
    return (
        <>
            <Container>
                <div className='header'>
                    <Header />
                </div>
                <Card style={{ marginTop: '6rem' }} className="shadow p-3 mb-5 bg-white rounded">
                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: 'bold' }}>NCB Scoring System</Typography>
                        </Grid>
                        <Grid item >
                            <Button variant="contained">Update</Button>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs>
                            <Typography variant='h6'>คุณภาพบุคคล</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6'>คะแนนรวม: {score.person_quality.summation}</Typography>
                        </Grid>
                    </Grid>
                    <Typography component={'span'}>
                        <Row style={{ marginTop: '1rem' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="อายุตัว" label="อายุตัว" type="number" defaultValue={score.person_quality.age} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="วุฒิการศึกษา" label="วุฒิการศึกษา" type="number" defaultValue={score.person_quality.education} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="อายุงาน" label="อายุงาน" type="number" defaultValue={score.person_quality.work_experience} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="ลักษณะงาน" label="ลักษณะงาน" type="number" defaultValue={score.person_quality.work_type} InputLabelProps={{ shrink: true, }} /></th>
                                    </tr>
                                </thead>
                            </table>
                        </Row>
                    </Typography>

                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs>
                            <Typography variant='h6'>คุณภาพนายจ้าง</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6'>คะแนนรวม: {score.employer.summation}</Typography>
                        </Grid>
                    </Grid>
                    <Typography component={'span'}>
                        <Row style={{ marginTop: '1rem' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="ประเภทนายจ้าง" label="ประเภทนายจ้าง" type="number" defaultValue={score.employer.employer_type} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="รายได้ปีล่าสุด" label="รายได้ปีล่าสุด" type="number" defaultValue={score.employer.income} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="กำไรปีล่าสุด" label="กำไรปีล่าสุด" type="number" defaultValue={score.employer.profit} InputLabelProps={{ shrink: true, }} /></th>
                                    </tr>
                                </thead>
                            </table>
                        </Row>
                    </Typography>

                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs>
                            <Typography variant='h6'>รายได้-รายจ่าย</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6'>คะแนนรวม: {score.income_expense.summation}</Typography>
                        </Grid>
                    </Grid>
                    <Typography component={'span'}>
                        <Row style={{ marginTop: '1rem' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="รายได้รวม" label="รายได้รวม" type="number" defaultValue={score.income_expense.net_balance} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="คงเหลือสุทธิ" label="คงเหลือสุทธิ" type="number" defaultValue={score.income_expense.total_income} InputLabelProps={{ shrink: true, }} /></th>
                                    </tr>
                                </thead>
                            </table>
                        </Row>
                    </Typography>

                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs>
                            <Typography variant='h6'>เครคิต</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6'>คะแนนรวม: {score.credit.summation}</Typography>
                        </Grid>
                    </Grid>
                    <Typography component={'span'}>
                        <Row style={{ marginTop: '1rem' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="NCB Score" label="NCB Score" type="number" defaultValue={score.credit.ncb_score} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="จำนวนเดือนที่ไม่เสีย" label="จำนวนเดือนที่ไม่เสีย" type="number" defaultValue={score.credit.undamaged_months} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="อายุบัญชีที่ Active ยาวที่สุด" label="อายุบัญชีที่ Active ยาวที่สุด" type="number" defaultValue={score.credit.longest_active_account} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="บัญชีที่เสีย 4 งวดขึ้นไป" label="บัญชีที่เสีย 4 งวดขึ้นไป" type="number" defaultValue={score.credit.bad_account} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="ประวัติเสียกับ NCB" label="ประวัติเสียกับ NCB" type="number" defaultValue={score.credit.bad_history} InputLabelProps={{ shrink: true, }} /></th>
                                    </tr>
                                </thead>
                            </table>
                        </Row>
                    </Typography>

                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs>
                            <Typography variant='h6'>เครคิต (หนี้ใหม่)</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6'>คะแนนรวม: {score.new_credit.summation}</Typography>
                        </Grid>
                    </Grid>
                    <Typography component={'span'}>
                        <Row style={{ marginTop: '1rem' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="ภาระหนี้ใหม่ต่อภาระหนี้ทั้งหมด (%)" label="ภาระหนี้ใหม่ต่อภาระหนี้ทั้งหมด (%)" type="number" defaultValue={score.new_credit.new_indebtedness} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="การขอสินเชื่อใหม่" label="การขอสินเชื่อใหม่" type="number" defaultValue={score.new_credit.new_loan} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="แนวโน้มการขอสินเชื่อใหม่" label="แนวโน้มการขอสินเชื่อใหม่" type="number" defaultValue={score.new_credit.trend} InputLabelProps={{ shrink: true, }} /></th>
                                    </tr>
                                </thead>
                            </table>
                        </Row>
                    </Typography>

                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs>
                            <Typography variant='h6'>คุณภาพ Statement</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6'>คะแนนรวม: {score.quality_stm.summation}</Typography>
                        </Grid>
                    </Grid>
                    <Typography component={'span'}>
                        <Row style={{ marginTop: '1rem' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="เงินเดือนอยู่นานเท่าไร" label="เงินเดือนอยู่นานเท่าไร" type="number" defaultValue={score.quality_stm.long_salary} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="เงินอื่นๆที่เข้าบัญชีอยู่นานเท่าไร (ต่อครั้ง)" label="เงินอื่นๆที่เข้าบัญชีอยู่นานเท่าไร (ต่อครั้ง)" type="number" defaultValue={score.quality_stm.other_money} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="รายการเข้าจำนวนกี่ครั้ง (เต็มเดือนล่าสุด)" label="รายการเข้าจำนวนกี่ครั้ง (เต็มเดือนล่าสุด)" type="number" defaultValue={score.quality_stm.total} InputLabelProps={{ shrink: true, }} /></th>
                                    </tr>
                                    <tr>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="เงินเข้ารวมกี่บาท (เต็มเดือนล่าสุด) ไม่รวมโบนัส" label="เงินเข้ารวมกี่บาท (เต็มเดือนล่าสุด) ไม่รวมโบนัส" type="number" defaultValue={score.quality_stm.entry} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="จำนวนเงินเข้าเป็นกี่เท่าของเงินเดือน" label="จำนวนเงินเข้าเป็นกี่เท่าของเงินเดือน" type="number" defaultValue={score.quality_stm.times} InputLabelProps={{ shrink: true, }} /></th>
                                        <th className='small-text' style={{ padding: '0.5rem' }}><TextField inputProps={{ min: 0 }} fullWidth id="ประมาณยอดเงินอื่นๆเข้า STM ต่อครั้ง (ไม่นับเงินเดือน)" label="ประมาณยอดเงินอื่นๆเข้า STM ต่อครั้ง (ไม่นับเงินเดือน)" type="number" defaultValue={score.quality_stm.other_money_no_salary} InputLabelProps={{ shrink: true, }} /></th>
                                    </tr>
                                </thead>
                            </table>
                        </Row>
                    </Typography >
                    {/* <Grid container style={{ marginTop: '0.5rem' }} >
                        <Grid item xs>
                            <Typography variant='h6'>ข้อมูลเสริม</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6'>คะแนนรวม:</Typography>
                        </Grid>
                    </Grid> */}
                    {/* <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs>
                            <Typography variant='h6'>ข้อมูลสรุป</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6'>คะแนนรวม:</Typography>
                        </Grid>
                    </Grid> */}
                    <Typography variant='h4' align='right' style={{ marginTop: '1rem', fontWeight: 'bold' }}>คะแนนรวมทั้งหมด: {score.total_summation}</Typography>

                </Card>
            </Container>
        </>
    )
}