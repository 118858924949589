import { useState,  } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../page/header';

import {
    Card,
    Col,
    Row,
    Button,
    Form,
    Table,
} from 'react-bootstrap';

// import Table from 'react-bootstrap/Table';
import * as partnerHttpServices from '../../src/services/services';

export default function App() {
    const [data, setData] = useState();
    const [test, setTest] = useState();
    const [find, setFind] = useState(false)

    const getToken = () => {
        let token = localStorage.getItem('token')
        return token
    }

    function search_table() {
        if (find === true) {
            return Object.values(test.results).map((value, index) => {
                return (
                    <tr key={index}>
                        <td style={{ textAlign: 'center' }}>{value.properties.contract_no}</td>
                        <td style={{ textAlign: 'center' }}>{value.id}</td>
                        <td style={{ textAlign: 'center' }}>{value.properties.firstname}{' '}{value.properties.lastname}</td>
                        <td style={{ textAlign: 'center' }}>{value.properties.offer_limit}</td>
                        <td style={{ textAlign: 'center' }}>{value.properties.a_closingdate}</td>
                        <td style={{ textAlign: 'center' }}>{value.properties.conclude}</td>
                        <td style={{ textAlign: 'center' }}><button id="btn-detail" className="btn btn-primary" onClick={() => handleClick(value.id)}>ไฟล์ NCB</button></td>
                    </tr>
                )
            })
        }
    }

    function handleClick(data) {
        if (data !== undefined) {
            console.log(data)
            window.location.href = "/ncb/list/" + data
        }
    };

    function handleSubmit() {
        if (data === undefined || data === '') {
            alert('กรุณากรอกเลขบัตรประชาชน')
        } else {
            partnerHttpServices.getContactID(data, getToken())
                .then((res) => {
                    console.log(res)
                    setTest(res.data.data)
                    setFind(true)
                    if (res.data.data.total === 0) {
                        alert('ค้นหาไม่พบ')
                    }
                })
        }
    }


    if (localStorage.getItem('token') != null) {
        return (
            <div className='container'>

                <div className='header'>
                    <Header />
                </div>
                <Card style={{ marginTop: '6rem' }} className="shadow p-3 mb-5 bg-white rounded">
                    <Row style={{ padding: '2.5rem', }}>
                        <Col md={3}><Card.Title as="h5" style={{ paddingTop: '0.5rem' }}>ค้นหาด้วยเลขบัตรประชาชน</Card.Title></Col>
                        <Col md={3}>
                            <Form.Control id='number' type="id" placeholder="กรุณากรอกเลขบัตรประชาชน" onChange={e => { setData(e.target.value) }} />
                        </Col>
                        <Col md={2} >
                            <Button
                                id="btn-search"
                                style={{ backgroundColor: '#7848f9', borderColor: '#7848f9' }}
                                onClick={handleSubmit}>ค้นหา</Button>
                        </Col>
                    </Row>
                </Card>

                <Card style={{ marginTop: '1rem' }} className="shadow p-3 mb-5 bg-white rounded">
                    <Card.Body>
                        <Table hover style={{ marginTop: '2rem' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '10%' }}>เลขสัญญา</th>
                                    <th style={{ textAlign: 'center', width: '15%' }}>Contact ID</th>
                                    <th style={{ textAlign: 'center', width: '25%' }}>ชื่อ-สกุล</th>
                                    <th style={{ textAlign: 'center', width: '15%' }}>วงเงิน</th>
                                    <th style={{ textAlign: 'center', width: '15%' }}>วันที่ปิดบัญชี</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>conclude</th>
                                    <th style={{ textAlign: 'center', width: '10%' }}>detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {search_table()}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div >
        )
    }else{
        window.location = '/ncb/login'
    }
}