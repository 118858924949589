import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

let delayed;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// const legendMargin = {
//   id: "legendMargin",
//   beforeInit: function (chart) {
//     const fitValue = chart.legend.fit;
//     chart.legend.fit = function fit() {
//       fitValue.bind(chart.legend)();
//       return (this.height += 100);
//     };
//   }
// };
// Chart.Legend.prototype.afterFit = function() {
//   this.height = this.height + 50;
// };

export const options = {
  layout: {
    padding: 50
  },
  plugins: {
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart - Stacked',
    // },
    
    legend: {
      display: true,
      fullSize: true,
      position: 'bottom',
      align: 'start',
      labels: {
        padding: 40,
      },
    },
  },
  responsive: true,
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['ชำระแล้ว', 'ติดต่อไม่ได้', 'นัดชำระ', 'หนี้สูญทางบัญชี', 'ไม่นัดชำระ', 'ไม่ได้ติดตาม'];

export const data = {
  labels,
  datasets: [
    {
      label: 'บัญชีนอกงบดุล',
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
       data:[500,500,500,500,500], 
      backgroundColor: '#F363A3',
    },
    {
      label: 'บัญชีในงบดุล',
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 200 })),
      data:[400,600,500,500,500], 
      backgroundColor: '#A454BC',
    },
    {
      label: 'ลูกหนี้ NP Pico',
      data:[400,600,500,500,500], 
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 300 })),
      backgroundColor: '#145CAC',
    },
  ],
};

export default function App() {
  return <Bar options={options} data={data} />;
}
