import axios from "axios";
import config from '../config.json';

//production
 const ROOT_URL = config.PROD_API;
//dev
//  const ROOT_URL = config.DEV_API;
 
export const addUser = (object, token, callback) => {
    return axios.post(`${ROOT_URL}/users/`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const editUser = (object, token, callback) => {
    return axios.put(`${ROOT_URL}/users`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const changePassword = (object, token, callback) => {
    return axios.post(`${ROOT_URL}/changePassword`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getUsers = (token) => {
    return axios.get(`${ROOT_URL}/users`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const onDeleteUser = (user_id,token) => {
   
    return axios.delete(`${ROOT_URL}/users`,{ data:{
        user_id:user_id
      },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const onSetActive = (data,token) => {
   
    return axios.patch(`${ROOT_URL}/users`,data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const getRole = (token) => {
    return axios.get(`${ROOT_URL}/roles/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const getRoleDetail = (data,token) => {
    return axios.get(`${ROOT_URL}/role/`+ data ,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const addRole = (data,token) => {
    return axios.post(`${ROOT_URL}/roles/`,data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const editRole = (data,token) => {
    return axios.put(`${ROOT_URL}/roles`,data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const deleteRole = (role_id,token) => {
    return axios.delete(`${ROOT_URL}/roles/`,{ data:{
        role_id:role_id
      },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const resetPassword = (email,token) => {
    return axios.post(`${ROOT_URL}/resetPassword`,email,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};