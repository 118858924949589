import React from 'react';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import App from './App';
import List from './components/list';
import Login from './components/user_login'
import Search from './page/search_menu'
import Detail1 from './page/ncb_detail/ncb_detail_1'
import Detail2 from './page/ncb_detail/ncb_detail_2'
import Detail3 from './page/ncb_detail/ncb_detail_3'
import Detail4 from './page/ncb_detail/ncb_detail_4'
import Summarize from './page/ncb_summarize/sum'

import Dashboard from './page/dashboard';
import NewLogin from './page/login/login'
import LoginOrg from './page/login/login_org'

import Score from './page/ncb_summarize/score_sumarize'

import ContactManagement from './page/CMS/contact_management'
import ContactCollector from './page/CMS/contact_collector'
import ContactDetail from './page/CMS/contact_detail'

import UserManagement from './page/user_management/user_management'
import UserManagementAddUser from './page/user_management/add_user'
import UserManagementEditUser from './page/user_management/edit_user'
import UserManagementResetPass from './page/user_management/reset_password'
import UserRoleManagement from './page/user_management/user_role_management'
import UserRoleManagementAddRole from './page/user_management/add_role'
import UserRoleManagementEditRole from './page/user_management/edit_role'

import FileAnalysis from './page/statement/FileManagement.js'
import StatementAnalysis from './page/statement/StatementAnalysis'

import Report1 from './page/report/OverallReport1'
import Report2 from './page/report/OverallReport2'

import { store } from './store/store'
import { Provider } from 'react-redux';
import TestUploadStatement from './page/test/TestUploadStatement';

//import test 
import Test from './page/test/test'
import Test2 from './page/test/test2'
import Test3 from './page/test/test3'
import TestPieChart from './page/test/TestPieChart'
import TestUploadPDFStatement from './page/test/TestUploadPDFStatement';
import StatementList from './page/statement/StatementList';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<NewLogin />} />
          <Route path="/ncb/login" element={<Login />} />
          <Route path="/ncb/summarize/:contact_id" element={<Summarize />} />
          <Route path="/ncb/search" element={<Search />} />
          <Route path="/ncb/list/:contact_id" element={<List />} />
          <Route path="/ncb/detail_1/:contact_id" element={<Detail1 />} />
          <Route path="/ncb/detail_2/:contact_id" element={<Detail2 />} />
          <Route path="/ncb/detail_3/:contact_id" element={<Detail3 />} />
          <Route path="/ncb/detail_4/:contact_id" element={<Detail4 />} />
          {/* <Route path="/home/" element={<Home />} /> */}
          {/* <Route path="/register/" element={<Resigter />} /> */}
          {/* <Route path="/user_update/:id" element={<UserUpdate />} /> */}
          <Route path="/ncb/dev/dashboard" element={<Dashboard />} />
          <Route path="/dev/test" element={<Test />} />

          <Route path="/ncb/dev/score_summarize" element={<Score />} />

          <Route path="/login" element={<NewLogin />} />
          <Route path="/login_org" element={<LoginOrg />} />

          <Route path="/contact_management" element={<ContactManagement />} />
          <Route path="/contact_collector" element={<ContactCollector />} />
          <Route path="/contact_detail/:id" element={<ContactDetail />} />
          
          <Route path="/user_management" element={<UserManagement />} />
          <Route path="/user_management/add_user" element={<UserManagementAddUser />} />
          <Route path="/user_management/edit_user" element={<UserManagementEditUser />} />
          <Route path="/user_management/reset_password" element={<UserManagementResetPass />} />
          
          <Route path="/user_role_management" element={<UserRoleManagement />} />
          <Route path="/user_role_management/add_role" element={<UserRoleManagementAddRole />} />
          <Route path="/user_role_management/edit_role" element={<UserRoleManagementEditRole />} />

          <Route path="/file_management" element={<FileAnalysis />} />
          <Route path="/statement_analysis/:id" element={<StatementAnalysis />} />

          <Route path="/report1" element={<Report1 />} />
          <Route path="/report2" element={<Report2 />} />
          
          {/* Test route for test component */}
          <Route path="/dev/test" element={<Test />} />
          <Route path="/dev/test2" element={<Test2 />} />
          <Route path="/dev/test3" element={<Test3 />} />
          <Route path="/dev/pieChart" element={<TestPieChart />} />

          <Route path="/test/component/upload_statement" element={<TestUploadStatement />} />
          <Route path="/test/component/upload_pdf_statement" element={<TestUploadPDFStatement/>} />
        </Routes>
      </Provider>
    </BrowserRouter>
  );
}

export default App;