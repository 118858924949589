/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import './../sum.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import UploadStatement from '../../components/statement/UploadStatement';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Header from './../../page/dashboard'
import * as statementServices from '../../../src/services/statement';

import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from 'react-bootstrap/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Backdrop from '@mui/material/Backdrop';
import {
  Container,
} from 'react-bootstrap';
import UploadPDFStatement from '../../components/statement/UploadPDFStatement';

function createData(name, month_1, month_2, month_3, month_ave ,pico_score,weight,weighted) {
  if (name === undefined || name==null) { name = 0 }
  if (month_1 === undefined || month_1==null) { month_1 = 0 }
  if (month_2 === undefined || month_2==null) { month_2 = 0 }
  if (month_3 === undefined || month_3==null) { month_3 = 0 }
  if (month_ave === undefined || month_ave==null) { month_ave = 0 }
  if (pico_score === undefined || pico_score==null) { pico_score = 0 }
  if (weight === undefined || weight==null) { weight = 0 }
  if (weighted === undefined || weighted==null) { weighted = 0 }
  return { name, month_1, month_2, month_3, month_ave ,pico_score,weight,weighted};
}


export default function App() {
  const [data, setData] = React.useState({ data: [] })
  const { id } = useParams()
  let token = localStorage.getItem('token')
  // console.log(data)
  React.useEffect(() => {
    // console.log(id);
    statementServices.getStatement(token, id)
      .then((res) => {
        setData(res.data)
      })
  }, [])

  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open_PDF, setOpenPDF] = React.useState(false);


  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleTogglePDF = () => {
    setOpenPDF(!open_PDF);
  };

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      <>
        <Container>
          <Header />
          <Grid container style={{ marginTop: '6rem', marginBottom: '2rem' }}>
            <Grid item xs>
              <Typography variant='h4'>Statement Analysis Contract ID: {id}</Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              boxShadow: 1,
              borderRadius: 1,
              bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
              p: 1,
              mb: 2,
              fontSize: '0.875rem',
              fontWeight: '700',
            }}
          >
            <Grid container padding={0.5}>
              <Grid item xs>
                <Typography variant='h5' >รายการไฟล์ Statement</Typography>
              </Grid>
              {/* <Grid item>
                <Button variant="contained" onClick={handleToggle} >UPLOAD Excel</Button>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={open}
                  // contract_id={id}
                //ลบตรงนี้ออก
                //  onClick={handleClose}
                >
                  <UploadStatement />
                </Backdrop>
              </Grid> */}
              <Grid item sx={{ml: 1}}>
                <Button variant="contained" onClick={handleTogglePDF} >UPLOAD PDF</Button>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={open_PDF}
                //ลบตรงนี้ออก
                //  onClick={handleClose}
                >
                  <UploadPDFStatement 
                  mode={id} />
                </Backdrop>
              </Grid>
            </Grid>
          </Box>

          <div style={{ marginBottom: '5rem' }}>
            {console.log(data.data)}
            {

              data.data.map((value, index) => {
                const rows = [
                  createData('เงินเข้า Mean', value[index].data_statement.deposit.mean.month_1, value[index].data_statement.deposit.mean.month_2, value[index].data_statement.deposit.mean.month_3, value[index].data_statement.deposit.mean.month_ave),
                  createData('เงินเข้า Median', value[index].data_statement.deposit.median.month_1, value[index].data_statement.deposit.median.month_2, value[index].data_statement.deposit.median.month_3, value[index].data_statement.deposit.median.month_ave),
                  createData('เงินเข้า Mode', value[index].data_statement.deposit.mod.month_1, value[index].data_statement.deposit.mod.month_2, value[index].data_statement.deposit.mod.month_3, value[index].data_statement.deposit.mod.month_ave),
                  createData('เงินเข้า Frequency', value[index].data_statement.deposit.frequency.month_1, value[index].data_statement.deposit.frequency.month_2, value[index].data_statement.deposit.frequency.month_3, value[index].data_statement.deposit.frequency.month_ave),
                  createData('เงินเข้า ทั้งหมด', value[index].data_statement.deposit.sum.month_1, value[index].data_statement.deposit.sum.month_2, value[index].data_statement.deposit.sum.month_3, value[index].data_statement.deposit.sum.month_ave),

                  createData('เงินเข้าไม่ร่วมเงินเดือน Mean', value[index].data_statement.deposit_not_salary.mean.month_1, value[index].data_statement.deposit_not_salary.mean.month_2, value[index].data_statement.deposit_not_salary.mean.month_3, value[index].data_statement.deposit_not_salary.mean.month_ave),
                  createData('เงินเข้าไม่ร่วมเงินเดือน Median', value[index].data_statement.deposit_not_salary.median.month_1, value[index].data_statement.deposit_not_salary.median.month_2, value[index].data_statement.deposit_not_salary.median.month_3, value[index].data_statement.deposit_not_salary.median.month_ave),
                  createData('เงินเข้าไม่ร่วมเงินเดือน Mode', value[index].data_statement.deposit_not_salary.mod.month_1, value[index].data_statement.deposit_not_salary.mod.month_2, value[index].data_statement.deposit_not_salary.mod.month_3, value[index].data_statement.deposit_not_salary.mod.month_ave),
                  createData('เงินเข้าไม่ร่วมเงินเดือน Frequency', value[index].data_statement.deposit_not_salary.frequency.month_1, value[index].data_statement.deposit_not_salary.frequency.month_2, value[index].data_statement.deposit_not_salary.frequency.month_3, value[index].data_statement.deposit_not_salary.frequency.month_ave),
                  createData('เงินเข้าไม่ร่วมเงินเดือน ทั้งหมด', value[index].data_statement.deposit_not_salary.sum.month_1, value[index].data_statement.deposit_not_salary.sum.month_2, value[index].data_statement.deposit_not_salary.sum.month_3, value[index].data_statement.deposit_not_salary.sum.month_ave),

                  createData('เงินออก Mean', value[index].data_statement.withdraw.mean.month_1, value[index].data_statement.withdraw.mean.month_2, value[index].data_statement.withdraw.mean.month_3, value[index].data_statement.withdraw.mean.month_ave),
                  createData('เงินออก Median', value[index].data_statement.withdraw.median.month_1, value[index].data_statement.withdraw.median.month_2, value[index].data_statement.withdraw.median.month_3, value[index].data_statement.withdraw.median.month_ave),
                  createData('เงินออก Mode', value[index].data_statement.withdraw.mod.month_1, value[index].data_statement.withdraw.mod.month_2, value[index].data_statement.withdraw.mod.month_3, value[index].data_statement.withdraw.mod.month_ave),
                  createData('เงินออก Frequency', value[index].data_statement.withdraw.frequency.month_1, value[index].data_statement.withdraw.frequency.month_2, value[index].data_statement.withdraw.frequency.month_3, value[index].data_statement.withdraw.frequency.month_ave),
                  createData('เงินออก Sum', value[index].data_statement.withdraw.sum.month_1, value[index].data_statement.withdraw.sum.month_2, value[index].data_statement.withdraw.sum.month_3, value[index].data_statement.withdraw.sum.month_ave),

                  createData('ยอดคงเหลือ Mean', value[index].data_statement.balance.mean.month_1, value[index].data_statement.balance.mean.month_2, value[index].data_statement.balance.mean.month_3, value[index].data_statement.balance.mean.month_ave),
                  createData('ยอดคงเหลือ Median', value[index].data_statement.balance.median.month_1, value[index].data_statement.balance.median.month_2, value[index].data_statement.balance.median.month_3, value[index].data_statement.balance.median.month_ave),
                  createData('ยอดคงเหลือ Mode', value[index].data_statement.balance.mod.month_1, value[index].data_statement.balance.mod.month_2, value[index].data_statement.balance.mod.month_3, value[index].data_statement.balance.mod.month_ave),
                  createData('ยอดคงเหลือ Frequency', value[index].data_statement.balance.frequency.month_1, value[index].data_statement.balance.frequency.month_2, value[index].data_statement.balance.frequency.month_3, value[index].data_statement.balance.frequency.month_ave),
                  createData('ยอดคงเหลือ Sum', value[index].data_statement.balance.sum.month_1, value[index].data_statement.balance.sum.month_2, value[index].data_statement.balance.sum.month_3, value[index].data_statement.balance.sum.month_ave),

                  createData('ยอดคงเหลือสิ้นวัน Mean', value[index].data_statement.end_day_balance.mean.month_1, value[index].data_statement.end_day_balance.mean.month_2, value[index].data_statement.end_day_balance.mean.month_3, value[index].data_statement.end_day_balance.mean.month_ave),
                  createData('ยอดคงเหลือสิ้นวัน Median', value[index].data_statement.end_day_balance.median.month_1, value[index].data_statement.end_day_balance.median.month_2, value[index].data_statement.end_day_balance.median.month_3, value[index].data_statement.end_day_balance.median.month_ave),
                  createData('ยอดคงเหลือสิ้นวัน Mode', value[index].data_statement.end_day_balance.mod.month_1, value[index].data_statement.end_day_balance.mod.month_2, value[index].data_statement.end_day_balance.mod.month_3, value[index].data_statement.end_day_balance.mod.month_ave),
                  createData('ยอดคงเหลือสิ้นวัน Frequency', value[index].data_statement.end_day_balance.frequency.month_1, value[index].data_statement.end_day_balance.frequency.month_2, value[index].data_statement.end_day_balance.frequency.month_3, value[index].data_statement.end_day_balance.frequency.month_ave),
                  createData('ยอดคงเหลือสิ้นวัน Sum', value[index].data_statement.end_day_balance.sum.month_1, value[index].data_statement.end_day_balance.sum.month_2, value[index].data_statement.end_day_balance.sum.month_3, value[index].data_statement.end_day_balance.sum.month_ave),

                  createData('ยอดคงเหลือสิ้นวัน 5 วันก่อนรับเงินเดือน Mean', value[index].data_statement.end_5_day_balance.mean.month_1, value[index].data_statement.end_5_day_balance.mean.month_2, value[index].data_statement.end_5_day_balance.mean.month_3, value[index].data_statement.end_5_day_balance.mean.month_ave),
                  createData('ยอดคงเหลือสิ้นวัน 5 วันก่อนรับเงินเดือน Median', value[index].data_statement.end_5_day_balance.median.month_1, value[index].data_statement.end_5_day_balance.median.month_2, value[index].data_statement.end_5_day_balance.median.month_3, value[index].data_statement.end_5_day_balance.median.month_ave),
                  createData('ยอดคงเหลือสิ้นวัน 5 วันก่อนรับเงินเดือน Mode', value[index].data_statement.end_5_day_balance.mod.month_1, value[index].data_statement.end_5_day_balance.mod.month_2, value[index].data_statement.end_5_day_balance.mod.month_3, value[index].data_statement.end_5_day_balance.mod.month_ave),
                  createData('ยอดคงเหลือสิ้นวัน 5 วันก่อนรับเงินเดือน Sum', value[index].data_statement.end_5_day_balance.sum.month_1, value[index].data_statement.end_5_day_balance.sum.month_2, value[index].data_statement.end_5_day_balance.sum.month_3, value[index].data_statement.end_5_day_balance.sum.month_ave),

                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Mean', value[index].data_statement.end_day_few_minimum.mean.month_1, value[index].data_statement.end_day_few_minimum.mean.month_2, value[index].data_statement.end_day_few_minimum.mean.month_3, value[index].data_statement.end_day_few_minimum.mean.month_ave),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Median', value[index].data_statement.end_day_few_minimum.median.month_1, value[index].data_statement.end_day_few_minimum.median.month_2, value[index].data_statement.end_day_few_minimum.median.month_3, value[index].data_statement.end_day_few_minimum.median.month_ave),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Mode',value[index].data_statement.end_day_few_minimum.mod.month_1, value[index].data_statement.end_day_few_minimum.mod.month_2, value[index].data_statement.end_day_few_minimum.mod.month_3, value[index].data_statement.end_day_few_minimum.mod.month_ave),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Frequency',value[index].data_statement.end_day_few_minimum.frequency.month_1, value[index].data_statement.end_day_few_minimum.frequency.month_2, value[index].data_statement.end_day_few_minimum.frequency.month_3, value[index].data_statement.end_day_few_minimum.frequency.month_ave),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Sum',value[index].data_statement.end_day_few_minimum.sum.month_1, value[index].data_statement.end_day_few_minimum.sum.month_2, value[index].data_statement.end_day_few_minimum.sum.month_3, value[index].data_statement.end_day_few_minimum.sum.month_ave),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Mean'),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Median'),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Mode'),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Frequency'),
                  // createData('ยอดคงเหลือสิ้นวันช่วงทวงหนี้ (25-1) Sum'),
                  // createData('เงินเดือนอยู่นานเท่าไหร่', value[index].data_statement.salary_long_time.salary_long_time.month_1, value[index].data_statement.salary_long_time.salary_long_time.month_2, value[index].data_statement.salary_long_time.salary_long_time.month_3, value[index].data_statement.salary_long_time.salary_long_time.month_ave),
                  // createData('เงินเข้าอื่นๆอยู่นานเท่าไหร่', value[index].data_statement.not_salary_long_time.not_salary_long_time.month_1, value[index].data_statement.not_salary_long_time.not_salary_long_time.month_2, value[index].data_statement.not_salary_long_time.not_salary_long_time.month_3, value[index].data_statement.not_salary_long_time.not_salary_long_time.month_ave),
                 
                  createData('ส่วนต่างเงินเข้าออก', value[index].data_statement.withdraw_deposit_diff.money_diff.month_1, value[index].data_statement.withdraw_deposit_diff.money_diff.month_2, value[index].data_statement.withdraw_deposit_diff.money_diff.month_3, value[index].data_statement.withdraw_deposit_diff.money_diff.month_ave),
                  createData('เงินคงเหลือต่ำสุดในบัญชี ( LOW )', value[index].data_statement.pico_score.low_balance_account.month_1, value[index].data_statement.pico_score.low_balance_account.month_2, value[index].data_statement.pico_score.low_balance_account.month_3, value[index].data_statement.pico_score.low_balance_account.month_ave, value[index].data_statement.pico_score.low_balance_account.pico_score, value[index].data_statement.pico_score.low_balance_account.weight, value[index].data_statement.pico_score.low_balance_account.pico_score.weighted),
                  createData('เงินเดือนอยู่นานเท่าไหร่ หลังเงินเดือนออก (< 500 บาท)', value[index].data_statement.pico_score.how_long_balance_after_salary.month_1, value[index].data_statement.pico_score.how_long_balance_after_salary.month_2, value[index].data_statement.pico_score.how_long_balance_after_salary.month_3, value[index].data_statement.pico_score.how_long_balance_after_salary.month_ave, value[index].data_statement.pico_score.how_long_balance_after_salary.pico_score, value[index].data_statement.pico_score.how_long_balance_after_salary.weight, value[index].data_statement.pico_score.how_long_balance_after_salary.weighted),
                  createData('ค่า Mean เฉลี่ย 30 วัน(นับจากรอบเงินเดือนออก)', value[index].data_statement.pico_score.mean_30_day.month_1, value[index].data_statement.pico_score.mean_30_day.month_2, value[index].data_statement.pico_score.mean_30_day.month_3, value[index].data_statement.pico_score.mean_30_day.month_ave, value[index].data_statement.pico_score.mean_30_day.pico_score, value[index].data_statement.pico_score.mean_30_day.weight, value[index].data_statement.pico_score.mean_30_day.weighted),
                  createData('เงินคงเหลือสิ้นวัน ก่อนวันเงินเดือนออก', value[index].data_statement.pico_score.balance_before_salary.month_1, value[index].data_statement.pico_score.balance_before_salary.month_2, value[index].data_statement.pico_score.balance_before_salary.month_3, value[index].data_statement.pico_score.balance_before_salary.month_ave, value[index].data_statement.pico_score.balance_before_salary.pico_score, value[index].data_statement.pico_score.balance_before_salary.weight, value[index].data_statement.pico_score.balance_before_salary.weighted),
                ];

                return (
                  <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)} key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: '33%', flexShrink: 0 }}>
                       {value[index].bank}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary' }}>รอบระหว่างวันที่ {value[index].date_range}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: '10%' }} align="left">Topic</TableCell>
                            <TableCell style={{ width: '12%' }} align="center">เดือนที่ 1</TableCell>
                            <TableCell style={{ width: '12%' }} align="center">เดือนที่ 2</TableCell>
                            <TableCell style={{ width: '12%' }} align="center">เดือนที่ 3</TableCell>
                            <TableCell style={{ width: '12%' }} align="center">AVG 3 เดือน</TableCell>
                            <TableCell style={{ width: '12%' }} align="center">Compony Score</TableCell>
                            <TableCell style={{ width: '12%' }} align="center">%</TableCell>
                            <TableCell style={{ width: '12%' }} align="center">Weighted %</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {console.log(rows)}
                          {rows.map((row) => (
                          
                            <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              {/* <TableCell align="center">{(row.month_1)}</TableCell>
                              <TableCell align="center">{(row.month_2)}</TableCell>
                              <TableCell align="center">{(row.month_3)}</TableCell>
                              <TableCell align="center">{(row.month_ave)}</TableCell>
                              <TableCell align="center">{(row.pico_score)}</TableCell>
                              <TableCell align="center">{(row.weight)}</TableCell>
                              <TableCell align="center">{(row.weighted)}</TableCell> */}

                              <TableCell align="center">{(row.month_1) != null ?(row.month_1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }):0}</TableCell>
                              <TableCell align="center">{(row.month_2) != null ?(row.month_2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }):0}</TableCell>
                              <TableCell align="center">{(row.month_3) != null ?(row.month_3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }):0}</TableCell>
                              <TableCell align="center">{(row.month_ave) !=null ?(row.month_ave).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }):0}</TableCell>
                              <TableCell align="center">{(row.pico_score) !=null ?(row.pico_score).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }):0}</TableCell>
                              <TableCell align="center">{(row.weight) != null ?(row.weight).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }):0}%</TableCell>
                              <TableCell align="center">{(row.weighted)!= null ?(row.weighted).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }):0}</TableCell>

                              {/* <TableCell align="center">
                                <TextField
                                  required
                                  id="outlined-required"
                                  defaultValue=""
                                  size='small'
                                  style={{ width: 100 }}
                                  sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                  }}
                                />
                              </TableCell> */}
                              
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </div>
        </Container>
      </>

    )
  }