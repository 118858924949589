import axios from "axios";
const ROOT_URL = "https://hackathon-horacle-app.manmuson.com";

export const isDirectDebit = () => {
    return axios.get(`https://hackathon-horacle-app.manmuson.com/get_alluser`)
}

export const manualPayment = (object) => {
    console.log('object', object)
    return axios.post(`${ROOT_URL}/user_manual_payment`, object, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}