/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './../../page/dashboard'
import TableData from '../../components/table/table_file_management';
import './../sum.css'
import {
  Container,
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function App() {
  return (
    <>
      <Container>
        <div className='header'>
          <Header />
        </div>

        <Grid container style={{ marginTop: '6rem', marginBottom: '2rem' }} >
          <Grid item xs>
            <Typography variant='h4'>Contract File Management</Typography>
          </Grid>
        </Grid>

        <TableData />
        
      </Container>
    </>
  )
}