import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';

const style = {
    width: '100%',
    maxWidth: 500,
    boxShadow: 1,
    borderRadius: 1,
    margin: 10
};

export default function ListDividers() {
    return (
        <List sx={style} component="nav" aria-label="mailbox folders">
            <ListItem >
                <Box m={1} sx={{
                    width: '100%'
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 20 }} color="text.secondary">สรุปผลการติดตาม</Typography>
                        <InfoIcon color='disabled' fontSize='large' />
                    </Box>

                    <Typography sx={{ fontWeight: 'light', fontSize: 18 }}>รอบสัปดาห์ที่ 17 - 23/11/2022</Typography>
                </Box>
            </ListItem>
            <Divider />
            <Divider />
            <Divider />
            <ListItem sx={{ height: 200 }}>
                <ListItemText primary="Drafts" />
            </ListItem>
        </List>
    );
}