/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import * as partnerHttpServices from '../../../src/services/services';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Table from 'react-bootstrap/Table';
import { useState } from 'react';
// import { useParams } from "react-router-dom";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';

export default function App() {
    const [search, setSearch] = useState('');
    const [data, setData] = useState();
    const [find, setFind] = useState(false)

    const getToken = () => {
        let token = localStorage.getItem('token')
        return token
    }

    function financial(n) {
        if (n === 0) {
            return "-"
        } else {
            // return n?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            return n?.toLocaleString()
        }
    }

    function search_table() {
        if (find === true) {
            return Object.values(data.results).map((value, index) => {
                return (
                    <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        style={{height: 60}}
                    >
                        <TableCell align="left" component="th" scope="row">{index + 1} </TableCell>
                        <TableCell align="left">{value.properties.contract_no}</TableCell>
                        <TableCell align="left">{value.id}</TableCell>
                        <TableCell align="left">{value.properties.firstname}{' '}{value.properties.lastname}</TableCell>
                        <TableCell align="center">{financial(value.properties.offer_limit)}</TableCell>
                        <TableCell align="center">{value.properties.conclude}</TableCell>
                        <TableCell align="center"><Link href={"/ncb/list/" + value.id}>(รายละเอียด)</Link></TableCell>
                        <TableCell align="center"><Link href={"/statement_analysis/" + value.id}>(รายละเอียด)</Link></TableCell>
                    </TableRow >
                )
            })
        }
    }

    function handleSubmit() {
        if (search === undefined || search === '') {
            alert('กรุณากรอกเลขบัตรประชาชน')
        } else {
            partnerHttpServices.getContactID(search, getToken())
                .then((res) => {
                    console.log(res)
                    setData(res.data.data)
                    setFind(true)
                    if (res.data.data.total === 0) {
                        alert('ค้นหาไม่พบ')
                    }
                })
        }
    }

    return (
        <>
            <Box
                sx={{
                    boxShadow: 2,
                    borderRadius: 1,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    p: 1,
                    mb: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}
            >
                <Grid container padding={0.5} marginBottom={1}>
                    <Grid item xs>
                        <Typography variant='h5' >ค้นหาสัญญา</Typography>
                    </Grid>
                    <Grid item>
                        <TextField id="outlined-basic" label="เลขบัตรประชาชน" variant="outlined" size="small" value={search} onChange={e => { setSearch(e.target.value) }} />
                    </Grid>
                    <Grid item paddingLeft={5}>
                        <Button variant="contained" onClick={handleSubmit}>ค้นหา</Button>
                    </Grid>
                </Grid>

                <div style={{ marginBottom: '2rem' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" size='medium'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '5%' }} align="left">No</TableCell>
                                <TableCell style={{ width: '12%' }} align="left">เลขสัญญา</TableCell>
                                <TableCell style={{ width: '12%' }} align="left">Contract ID</TableCell>
                                <TableCell style={{ width: '12%' }} align="left">ชื่อ-สกุล</TableCell>
                                <TableCell style={{ width: '12%' }} align="center">วงเงิน</TableCell>
                                <TableCell style={{ width: '12%' }} align="center">ผลการพิจารณา</TableCell>
                                <TableCell style={{ width: '12%' }} align="center">จำนวน Files NCB</TableCell>
                                <TableCell style={{ width: '12%' }} align="center">จำนวน File Statement</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {search_table()}
                        </TableBody>
                    </Table>
                </div>
            </Box>

        </>
    )
}