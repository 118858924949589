/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './../../page/dashboard'
import InputAdornment from '@mui/material/InputAdornment';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import './../sum.css'
import Button from '@mui/material/Button';
import {
    Card,
    Col,
    Row,
    // Button,
    Container,
    // Form,
    Alert,
    ListGroup
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import * as userHttpServices from '../../../src/services/user_management_service';
import { Password } from '@mui/icons-material';
export default function App() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [user_data, setUserData] = useState(state)
    const [role, setRole] = useState('')
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [new_password, setNewPassword] = useState('');
    const [old_password, setOldPassword] = useState('');
    const [email, setEmail] = useState('');
    const [avatar, setAvatar] = useState('');
    const [open, setOpen] = useState(false);

    const getToken = () => {
        let token = localStorage.getItem('token')
        return token
    }
    useEffect(() => {

        setFirstname(user_data.first_name)
        setLastname(user_data.last_name)
        setUsername(user_data.Username)
        setEmail(user_data.Email)
        setRole(user_data.role_id)
    }, [])

    const onChangePassword = () => {
        setOpen(true)
        let data ={
            user_id:user_data.user_id,
            password_old:old_password,
            password_new:new_password

        }

        userHttpServices.changePassword(data, getToken())
        .then((res) => {
            console.log(res)
            if (res.data.response.status) {
                alert('Change Password Success')
                setOpen(!open)
            }
        })
        .catch((err) => {
            alert('Change Password Fail')
        })

    }

    const handleClose = () => {
        setOpen(!open)
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        let data = {
            "user_id": user_data.user_id,
            "first_name": firstname,
            "last_name": lastname,
            "email": email,
            "role_id": role
        }

        userHttpServices.editUser(data, getToken())
            .then((res) => {
                console.log(res)
                if (res.data.response.status) {
                    alert('Update User Success')
                }
            })
            .catch((err) => {

                alert('Update User Fail')
            })
    }
    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="old_password"
                        label="Old Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <TextField
                     
                        margin="dense"
                        id="new_password"
                        label="New Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={onChangePassword}>Change</Button>
                </DialogActions>
            </Dialog>


            <Container>
                <div className='header'>
                    <Header />
                </div>
                <Grid container style={{ marginTop: '6rem' }}>
                    <Grid item xs>
                        <Typography variant='h4' style={{ fontWeight: 'bold' }}>Edit User</Typography>
                    </Grid>
                </Grid>

                {/* <TableData /> */}

                <Card style={{ marginTop: '1.5rem', marginRight: '15%', marginLeft: '15%' }} className="shadow p-3 mb-5 bg-white rounded">
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <TextField required fullWidth  label="ชื่อ" variant="outlined" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </Grid>
                            <Grid item xs>
                                <TextField  required fullWidth  label="นามสกุล" variant="outlined" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                            <Grid item xs>
                                <TextField disabled required fullWidth id="outlined-basic" label="Username" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </Grid>
                            <Grid item xs>
                                {/* <TextField fullWidth id="outlined-basic" label="User role" variant="outlined" /> */}
                                <FormControl required fullWidth>
                                    <InputLabel id="select-label">User Role</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        id="select-label"
                                        label="User Role"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                    >
                                        <MenuItem value={1}>Collector Supervisor</MenuItem>
                                        <MenuItem value={2}>Collector Operator</MenuItem>
                                        <MenuItem value={3}>NCB Operator</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                            {/* <Grid item xs>
                                <TextField
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" color="primary" onClick={onChangePassword}>
                                                    <AutorenewIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid> */}
                            <Grid item xs>
                                <TextField required fullWidth id="outlined-basic" label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                            <Grid item xs></Grid>
                            <Grid item >
                                <Button  fullWidth color="success" variant="contained" onClick={handleClose}>change Password</Button>
                            </Grid>
                            <Grid item >
                                <Button type="submit" fullWidth variant="contained">EDIT</Button>
                            </Grid>
                            <Grid item >
                                <Button variant="contained" color="error" href='/user_management' >BACK</Button>
                            </Grid>
                        </Grid>


                    </Box>
                </Card>


            </Container>
        </>
    )
}


