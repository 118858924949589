/* eslint-disable react-hooks/exhaustive-deps */

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as userHttpServices from '../../src/services/services';
import * as React from 'react';
import { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../page/header';

import {
    Card,
    Col,
    Row,
    // Form,
    Table,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function App() {
    const [extra, setExtra] = React.useState('ไม่มี');
    const handleChange = (event) => {
        setExtra(event.target.value);
    };

    var status
    var setfinalObj
    var err = false

    const [data, setData] = useState()
    const [table, setTable] = useState()
    const [contactid, setContactId] = useState('')
    const [modalShow, setModalShow] = useState(false);

    var { contact_id } = useParams();

    const getToken = () => {
        let token = localStorage.getItem('token')
        return token
    }

    useEffect(() => {
        const upload = localStorage.getItem('upload');
        if (upload === 'ok') {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: 'อัปโหลดสำเร็จ'
            })
            localStorage.removeItem('upload');
        }

        setContactId(contact_id)
        userHttpServices.showUser(contact_id, getToken())
            .then((res) => {
                // console.log(res)
                setData(res.data.data)
                setTable(res.data.table)
            })
    }, [])

    function onClickCalculate() {
        // console.log(status)
        if (status === 'create') {
            // console.log(setfinalObj)
            userHttpServices.createUser(setfinalObj, getToken())
                .then((res) => {
                    alert('อัปโหลดไฟล์ NCB สำเร็จ')
                    localStorage.setItem('upload', 'ok')
                    window.location.reload(true);
                })
            console.log(setfinalObj)
        }
        else if (status === 'insert') {
            userHttpServices.insert(setfinalObj, getToken())
                .then((res) => {
                    alert('อัปโหลดไฟล์ NCB สำเร็จ')
                    localStorage.setItem('upload', 'ok')
                    window.location.reload(true);
                })
            console.log(setfinalObj)
        }
        else {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'error',
                title: 'อัปโหลดไม่สำเร็จ'
            })
        }
    }

    function MyVerticallyCenteredModal(props) {

        var jsonDataArray_PN = [];
        var jsonDataArray_ID = [];
        var jsonDataArray_PA = [];
        var jsonDataArray_IQ = [];
        var jsonDataArray_TL = [];
        var jsonDataArray_HS = [];

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        อัปโหลดไฟล์ NCB
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">ไฟล์ NCB</label>
                        <input className="form-control" type="file" id="formFile" accept='text/*' onChange={handleFileChange}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">ประเภทเสีย NCB พิเศษ</label>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={extra}
                                size="small"
                                onChange={handleChange}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                            >
                                <MenuItem value={'ไม่มี'}>ไม่มี</MenuItem>
                                <MenuItem value={'โดนอายัดิเงินเดือน'}>โดนอายัดเงินเดือน</MenuItem>
                                <MenuItem value={'บุคคลล้มละลาย'}>บุคคลล้มละลาย</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btn-cal" onClick={onClickCalculate}>คำนวณ</Button>
                </Modal.Footer>
            </Modal>
        );

        function handleFileChange(e) {
            let file = e.target.files[0]
            let reader = new FileReader();

            reader.readAsText(file);
            reader.onload = () => {
                readerFile(reader.result, file.name, props.contactid)
            }
            reader.onerror = () => {
                console.log('file error', reader.error)
            }
        }

        function readerFile(fileContent, name, contactid) {
            // console.log(fileContent)

            //TUEF Response Header
            let enquiryDate
            let today = new Date()

            if (isNaN(new Date(fileContent.substring(6, 14).replace(/(\d{2})(\d{2})(\d{4})/g, '$3-$2-$1')))) {
                enquiryDate = today.toISOString().slice(0, 10)
            } else {
                enquiryDate = fileContent.substring(6, 14).replace(/(\d{2})(\d{2})(\d{4})/g, '$3-$2-$1')
            }
            // console.log(enquiryDate)
            const jsonDataHeader = {
                segment: fileContent.substring(0, 4),
                version: fileContent.substring(4, 6),
                memberReferenceNumber: fileContent.substring(6, 31),
                country: fileContent.substring(31, 33),
                memberCode: fileContent.substring(33, 43),
                subjectReturnCode: fileContent.substring(43, 44),
                enquirtControlNumber: fileContent.substring(44, 53),
                enquityDate: enquiryDate,
            }

            if (jsonDataHeader.segment === 'TUEF') {
                //PN Name
                let PN = (fileContent.slice(fileContent.search('PN03'), fileContent.search('ID03')))

                for (let j = 0; j < 2; j++) {
                    var PN_Array = []
                    for (let i = 0; i < 15; i++) {
                        if (parseInt((PN.charAt(0) + PN.charAt(1))) === i || (PN.charAt(0) + PN.charAt(1)) === 'PN') {
                            PN_Array[i] = PN.slice(4, parseInt(PN.charAt(2) + PN.charAt(3)) + 4)
                            PN = (PN.slice(parseInt(PN.charAt(2) + PN.charAt(3)) + 4))
                            // console.log(PN_Array[i], i)
                        } else {
                            PN_Array[i] = undefined
                        }
                    }

                    if (PN_Array[7] !== undefined) PN_Array[7] = PN_Array[7].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')

                    const jsonData = {
                        segment: PN_Array[0],
                        familyName1: PN_Array[1],
                        familyName2: PN_Array[2],
                        firstName: PN_Array[4],
                        middle: PN_Array[5],
                        maritalStatus: PN_Array[6],
                        dateOfBirth: PN_Array[7],
                        gender: PN_Array[8],
                        title: PN_Array[9],
                        nationality: PN_Array[10],
                        numberOfChilden: PN_Array[11],
                        spouseName: PN_Array[12],
                        occupation: PN_Array[13],
                        consentToEnquire: PN_Array[14]
                    }
                    let key = PN_Array[0]
                    jsonDataArray_PN[key] = jsonData

                    // console.log(jsonDataArray_PN)
                    if (PN.length === 0) break
                }

                //ID Identification Number
                let ID
                if (fileContent.search('PA03') > 0) {
                    ID = (fileContent.slice(fileContent.search('ID03'), fileContent.search('PA03')))
                } else {
                    ID = ''
                }
                // console.log(ID)

                if (ID !== '') {
                    var ID_Array = []
                    do {
                        for (let i = 0; i < 4; i++) {
                            if (parseInt((ID.charAt(0) + ID.charAt(1))) === i || ((ID.charAt(0) + ID.charAt(1)) === 'ID' && i === 0)) {
                                ID_Array[i] = ID.slice(4, parseInt(ID.charAt(2) + ID.charAt(3)) + 4)
                                ID = (ID.slice(parseInt(ID.charAt(2) + ID.charAt(3)) + 4))
                            } else {
                                ID_Array[i] = undefined
                            }
                        }

                        const jsonData = {
                            segment: ID_Array[0],
                            IDType: ID_Array[1],
                            IDNumber: ID_Array[2],
                            IDIssueCountry: ID_Array[3],
                        }

                        let key = ID_Array[0]
                        jsonDataArray_ID[key] = jsonData

                        if (jsonDataArray_ID[key].segment === undefined) {
                            err = true
                            break
                        }
                    } while (ID !== '' && !err)
                }

                //PA Address
                let PA
                if (fileContent.search('TL04') > 0) {
                    PA = (fileContent.slice(fileContent.search('PA03'), fileContent.search('TL04')))
                } else if (fileContent.search('IQ04') > 0) {
                    PA = (fileContent.slice(fileContent.search('PA03'), fileContent.search('IQ04')))
                } else if (fileContent.search('SC02') > 0) {
                    PA = (fileContent.slice(fileContent.search('PA03'), fileContent.search('SC02')))
                } else {
                    PA = ''
                }

                if (PA !== '') {
                    var PA_Array = []
                    do {
                        for (let i = 0; i < 14; i++) {
                            if (parseInt((PA.charAt(0) + PA.charAt(1))) === i || (i === 0 && PA.charAt(0) + PA.charAt(1)) === 'PA') {
                                PA_Array[i] = PA.slice(4, parseInt(PA.charAt(2) + PA.charAt(3)) + 4)
                                PA = (PA.slice(parseInt(PA.charAt(2) + PA.charAt(3)) + 4))
                            } else {
                                PA_Array[i] = undefined
                            }
                        }

                        const jsonData = {
                            segment: PA_Array[0],
                            address1: PA_Array[1],
                            address2: PA_Array[2],
                            address3: PA_Array[3],
                            subDistrict: PA_Array[4],
                            district: PA_Array[5],
                            province: PA_Array[6],
                            country: PA_Array[7],
                            postalCode: PA_Array[8],
                            telephone: PA_Array[9],
                            telephoneType: PA_Array[10],
                            addressType: PA_Array[11],
                        }

                        let key = PA_Array[0]
                        jsonDataArray_PA[key] = jsonData

                        if (jsonDataArray_PA[key].segment === undefined) {
                            err = true
                            break
                        }
                    } while (PA !== '' && !err)
                }

                //TL Account
                let TL
                if (fileContent.search('IQ04') > 0) {
                    TL = (fileContent.slice(fileContent.search('TL04'), fileContent.search('IQ04')))
                } else if (fileContent.search('SC02') > 0) {
                    TL = (fileContent.slice(fileContent.search('TL04'), fileContent.search('SC02')))
                } else {
                    TL = ''
                }

                if (TL !== '') {
                    do {
                        var TL_Array = []
                        if ((TL.charAt(0) + TL.charAt(1)) === 'TL') {
                            for (let i = 0; i < 43; i++) {
                                if (parseInt((TL.charAt(0) + TL.charAt(1))) === i || (i === 0 && (TL.charAt(0) + TL.charAt(1)) === 'TL')) {

                                    TL_Array[i] = TL.slice(4, parseInt(TL.charAt(2) + TL.charAt(3)) + 4)
                                    TL = (TL.slice(parseInt(TL.charAt(2) + TL.charAt(3)) + 4))

                                } else {
                                    TL_Array[i] = undefined
                                }

                                if ((TL.charAt(0) + TL.charAt(1)) === 'HS') break
                            }
                        }

                        // if (TL_Array[0] !== undefined) {
                        if (TL_Array[12] === undefined) TL_Array[12] = '0'
                        if (TL_Array[13] === undefined) TL_Array[13] = '0'
                        if (TL_Array[14] === undefined) TL_Array[14] = '0'
                        if (TL_Array[21] === undefined) TL_Array[21] = '0'
                        if (TL_Array[37] === undefined) TL_Array[37] = '0'

                        if (TL_Array[8] !== undefined) TL_Array[8] = TL_Array[8].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                        if (TL_Array[9] !== undefined) TL_Array[9] = TL_Array[9].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                        if (TL_Array[10] !== undefined) TL_Array[10] = TL_Array[10].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                        if (TL_Array[11] !== undefined) TL_Array[11] = TL_Array[11].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                        if (TL_Array[19] !== undefined) TL_Array[19] = TL_Array[19].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                        if (TL_Array[30] !== undefined) TL_Array[30] = TL_Array[30].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                        if (TL_Array[31] !== undefined) TL_Array[31] = TL_Array[31].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')

                        const jsonData = {
                            segment: TL_Array[0],
                            memberCode: TL_Array[1],
                            memberShortName: TL_Array[2],
                            accountNumber: TL_Array[3],
                            accountType: TL_Array[4],
                            ownershipIndicator: TL_Array[5],
                            currencyCode: TL_Array[6],
                            dateAccountOpened: TL_Array[8],
                            dateOfLastPayment: TL_Array[9],
                            dateAccountClosed: TL_Array[10],
                            asOfDate: TL_Array[11],
                            creditLimit: TL_Array[12],
                            amountOwed: TL_Array[13],
                            amountPastDue: TL_Array[14],
                            defaultDate: TL_Array[19],
                            installmentFrequency: TL_Array[20],
                            installmentAmount: TL_Array[21],
                            installmentNumberOfPayment: TL_Array[22],
                            accountStatus: TL_Array[23],
                            loanClass: TL_Array[24],
                            paymentHistory1: TL_Array[28],
                            paymentHistory2: TL_Array[29],
                            paymentHistoryStartDate: TL_Array[30],
                            paymentHistoryEndDate: TL_Array[31],
                            loanObjective: TL_Array[32],
                            collateral1: TL_Array[33],
                            collateral2: TL_Array[34],
                            collateral3: TL_Array[35],
                            dateOfLastDebtRestructure: TL_Array[36],
                            percentPayment: TL_Array[37],
                            typeOfCreditCard: TL_Array[38],
                            numberOfCoborrowers: TL_Array[39],
                            unitMake: TL_Array[40],
                            unitModel: TL_Array[41],
                            creditTypeFlag: TL_Array[42],
                        }

                        let key = TL_Array[0]
                        jsonDataArray_TL[key] = jsonData
                        // }

                        if (jsonDataArray_TL[key].segment === undefined) {
                            err = true
                            break
                        }

                        if ((TL.charAt(0) + TL.charAt(1)) === 'HS') {
                            let HS_Array = []

                            for (let j = 0; j < 50; j++) {
                                for (let i = 0; i < 5; i++) {
                                    if (parseInt((TL.charAt(0) + TL.charAt(1))) === i || (i === 0 && (TL.charAt(0) + TL.charAt(1)) === 'HS')) {
                                        HS_Array[i] = TL.slice(4, parseInt(TL.charAt(2) + TL.charAt(3)) + 4)
                                        TL = (TL.slice(parseInt(TL.charAt(2) + TL.charAt(3)) + 4))
                                    } else {
                                        HS_Array[i] = undefined
                                    }
                                }

                                if (HS_Array[1] !== undefined) HS_Array[1] = HS_Array[1].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                                if (HS_Array[4] === undefined) HS_Array[4] = 0

                                const jsonData = {
                                    segment: HS_Array[0],
                                    asOfDate: HS_Array[1],
                                    overDueMonths: HS_Array[2],
                                    creditLimit: HS_Array[3],
                                    amountOwed: HS_Array[4],
                                }


                                // eslint-disable-next-line react-hooks/exhaustive-deps
                                jsonDataArray_HS = jsonDataArray_HS.concat(jsonData)

                                if ((TL.charAt(0) + TL.charAt(1)) === 'TL' || (TL === '')) break
                            }
                        }
                    } while (TL !== '' && !err)
                }

                //IQ Enquity
                let IQ
                if (fileContent.search('DR03') > 0) {
                    IQ = (fileContent.slice(fileContent.search('IQ04'), fileContent.search('DR03')))
                } else if (fileContent.search('SC02') > 0) {
                    IQ = (fileContent.slice(fileContent.search('IQ04'), fileContent.search('SC02')))
                } else {
                    IQ = ''
                }

                if (IQ !== '') {
                    do {
                        var IQ_Array = []
                        for (let i = 0; i < 8; i++) {
                            if (parseInt((IQ.charAt(0) + IQ.charAt(1))) === i || (i === 0 && IQ.charAt(0) + IQ.charAt(1) === 'IQ')) {
                                IQ_Array[i] = IQ.slice(4, parseInt(IQ.charAt(2) + IQ.charAt(3)) + 4)
                                IQ = (IQ.slice(parseInt(IQ.charAt(2) + IQ.charAt(3)) + 4))
                            } else {
                                IQ_Array[i] = undefined
                            }
                        }

                        if (IQ_Array[1] !== undefined) IQ_Array[1] = IQ_Array[1].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                        if (IQ_Array[2] !== undefined) IQ_Array[2] = IQ_Array[2].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')

                        const jsonData = {
                            segment: IQ_Array[0],
                            dateOfEnquiry: IQ_Array[1],
                            timeOfEnquiry: IQ_Array[2],
                            memberCode: IQ_Array[3],
                            memberShortName: IQ_Array[4],
                            enquiryPurpose: IQ_Array[5],
                            enquityAmount: IQ_Array[6],
                            currencyCode: IQ_Array[7],
                        }

                        let key = IQ_Array[0]
                        jsonDataArray_IQ[key] = jsonData

                        if (jsonDataArray_IQ[key].segment === undefined) {
                            err = true
                            break
                        }
                    } while (IQ !== '' && !err)
                }

                //DR

                //SC Score Segment
                let SC = (fileContent.slice(fileContent.search('SC02'), fileContent.search('ES05')))

                var SC_Array = []
                for (let i = 0; i < 20; i++) {
                    if (parseInt((SC.charAt(0) + SC.charAt(1))) === i || (SC.charAt(0) + SC.charAt(1)) === 'SC') {
                        SC_Array[i] = SC.slice(4, parseInt(SC.charAt(2) + SC.charAt(3)) + 4)
                        SC = (SC.slice(parseInt(SC.charAt(2) + SC.charAt(3)) + 4, SC.length))
                    } else {
                        SC_Array[i] = undefined
                    }
                }

                const jsonDataArray_SC = {
                    segment: SC_Array[0],
                    scoreVersion: SC_Array[1],
                    scoreSegment: SC_Array[2],
                    recalibrationVersion: SC_Array[3],
                    scoreVersionDate: SC_Array[4],
                    score: SC_Array[5],
                    scoreGrade: SC_Array[6],
                    odds: (10000 - SC_Array[7] + ':' + parseInt(SC_Array[7])),
                    reasonCode1: SC_Array[13],
                    reasonCode2: SC_Array[14],
                    reasonCode3: SC_Array[15],
                    reasonCode4: SC_Array[16],
                    reasonCode5: SC_Array[17],
                    errorCode: SC_Array[18],
                    enquirtControlNumber: SC_Array[19],
                    status: extra,
                }

                //ES End

                const finalObj = {
                    file: name,
                    // file_detail: fileContent,
                    ncb_id: fileContent.substring(44, 53),
                    uploadDate: today,
                    header: jsonDataHeader,
                    score: jsonDataArray_SC,
                    name: Object.assign({}, jsonDataArray_PN),
                    id: Object.assign({}, jsonDataArray_ID),
                    address: Object.assign({}, jsonDataArray_PA),
                    iq: Object.assign({}, jsonDataArray_IQ),
                    account: Object.assign({}, jsonDataArray_TL),
                    history: Object.assign({}, jsonDataArray_HS),
                }
                // console.log(finalObj)

                userHttpServices.checkID(finalObj.ncb_id, getToken())
                    .then((res) => {
                        // console.log('check', res.data)
                        // console.log(table.length)

                        if (res.data.status === false) {
                            alert('ไฟล์ NCB นี้มีการอัปโหลดแล้ว ที่ Contact ID: ' + res.data.data[0]?.contact_id)
                            setModalShow(false)
                        }
                        if (err) {
                            alert('ไฟล์ NCB นี้มีปัญหา รบกวนติดต่อกับทางทีมทรานฟินเมชั่น')
                            console.log('err', err)
                            err = false
                        }
                        else {
                            if (table.length === 0) {
                                status = 'create'
                                setfinalObj = {
                                    contact_id: contactid,
                                    ncb_files: {
                                        ncb1: finalObj
                                    }
                                }
                            }
                            else if (table.length <= 4) {
                                let key = 'ncb' + (table.length + 1)
                                status = 'insert'
                                setfinalObj = {
                                    contact_id: contactid,
                                    [key]: finalObj
                                }
                            }
                            else {
                                alert('ขณะนี้สามารถอัปโหลดไฟล์ NCB ได้สูงสุดที่ 4 ไฟล์')
                                setModalShow(false)
                            }
                        }

                    })
            } else {
                alert('กรุณาอัปโหลดไฟล์ NCB ที่ถูกต้อง')
                setModalShow(false)
            }

        }
    }

    function handleClickDetail(data, index) {
        if (data !== undefined) {
            if (index === 1) {
                window.location.href = "/ncb/detail_1/" + data
            } else if (index === 2) {
                window.location.href = "/ncb/detail_2/" + data
            } else if (index === 3) {
                window.location.href = "/ncb/detail_3/" + data
            } else if (index === 4) {
                window.location.href = "/ncb/detail_4/" + data
            }
        } else {
            alert('id not founded')
        }
    };

    function handleSubmit(contact_id) {
        window.location.href = "/ncb/summarize/" + contact_id
    }
    function del(contact_id) {
        Swal.fire({
            title: 'ต้องการลบข้อมูล?',
            text: "คุณต้องการลบข้อมูล NCB ทั้งหมดของลูกค้าท่านนี้!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#D50000',
            confirmButtonText: 'ลบข้อมูลทั้งหมด',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'สำเร็จ!',
                    text: 'ระบบทำการลบข้อมูลเรียบร้อยแล้ว',
                    icon: 'success',
                    confirmButtonColor: '#0d6efd',
                    confirmButtonText: 'ตกลง',
                }).then(() => {
                    userHttpServices.del(contact_id, getToken())
                    window.location.reload(true);
                })
            }
        })
    }

    function show_summarize() {
        if (table.length !== 0) {
            return (
                <>
                    <Col md={{ span: 2 }}>
                        <Button
                            id="btn-search"
                            style={{ backgroundColor: '#D5D8DC', borderColor: '#D5D8DC' }}
                            onClick={() => del(data[0]?.contact_id)}>
                            ลบข้อมูล NCB ทั้งหมด
                        </Button>
                    </Col>
                    <Col md={{ span: 1 }}>
                        <Button
                            id="btn-search"
                            // style={{ backgroundColor: '#7848f9', borderColor: '#7848f9'}}
                            onClick={() => handleSubmit(data[0]?.contact_id)}>
                            สรุปผล
                        </Button>
                    </Col>
                </>
            )

        }
    }


    if (localStorage.getItem('token') != null) {
        if (data === undefined) {
            return <div style={{ margin: '2rem' }}>Loading NCB Data...</div>;
        } else {
            return (
                <>
                    {/* {console.log('>>', hubspotData)} */}
                    {/* {console.log('<<',table)} */}
                    <div className='container'>
                        <div className='header'>
                            <Header />
                        </div>
                        <div className='cardBox'>
                            <Card style={{ marginTop: '6rem' }} className="shadow p-3 mb-5 bg-white rounded">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Button id="btn-upload" variant="primary" onClick={() => setModalShow(true)}>
                                                อัปโหลดไฟล์ NCB
                                            </Button>
                                        </Col>
                                        {show_summarize()}
                                    </Row>
                                    <Table hover style={{ marginTop: '2rem' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center', width: '5%' }}>#</th>
                                                <th style={{ textAlign: 'center', width: '15%' }}>วันที่สืบค้น</th>
                                                <th style={{ textAlign: 'center', width: '15%' }}>วันที่อัปโหลด</th>
                                                <th style={{ textAlign: 'center', width: '20%' }}>ชื่อ-สกุล</th>
                                                <th style={{ textAlign: 'center', width: '20%' }}>ไฟล์ NCB</th>
                                                <th style={{ textAlign: 'center', width: '10%' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(table).map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: 'center' }}>{value.order}</td>
                                                        <td style={{ textAlign: 'center' }}>{value.enquiryDate}</td>
                                                        <td style={{ textAlign: 'center' }}>{value.uploadDate.slice(0, 10)}</td>
                                                        <td style={{ textAlign: 'center' }}>{value.name}</td>
                                                        <td style={{ textAlign: 'center' }}>{value.file}</td>
                                                        <td style={{ textAlign: 'center' }}><button id='btn-detail' className="btn btn-primary" onClick={() => handleClickDetail(data[0].ncb_files['ncb' + (index + 1)].ncb_id, index + 1)}>รายละเอียด</button></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        contactid={contactid}
                    />
                </>


            );
        }
    }
    else {
        window.location = '/ncb/login'
    }
}
export default App;
