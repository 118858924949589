/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './../../page/dashboard'
import './../sum.css'
import Button from '@mui/material/Button';
import {
    Card,
    Col,
    Row,
    // Button,
    Container,
    // Form,
    Alert,
    ListGroup
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from "@mui/material/Avatar";
import TextField from '@mui/material/TextField';

import TableData from '../../components/table/table_users';
import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import * as userHttpServices from '../../../src/services/services';
import { ConfirmProvider } from "material-ui-confirm";
export default function App() {
    return (
        <>
            <Container>
                <div className='header'>
                    <Header />
                </div>
                <Grid container style={{ marginTop: '6rem' }}>
                    <Grid item xs>
                        <Typography variant='h4' style={{ fontWeight: 'bold' }}>User Management</Typography>
                    </Grid>
                    <Grid item xs style={{ display: "contents", float: "right" }}>
                        <Button variant="contained" href='/user_management/add_user' >Add User</Button>
                    </Grid>
                </Grid>
                <ConfirmProvider>
                    <TableData />
                </ConfirmProvider>
            </Container>
        </>
    )
}