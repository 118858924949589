/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './../../page/dashboard'
import TableData from '../../components/table/table_role_management';
import './../sum.css'
import Button from '@mui/material/Button';
import {
    Container,
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ConfirmProvider } from "material-ui-confirm";

export default function App() {
    return (
        <>
            <Container>
                <div className='header'>
                    <Header />
                </div>
                <Grid container style={{ marginTop: '6rem' }}>
                    <Grid item xs>
                        <Typography variant='h4' style={{ fontWeight: 'bold' }}>User Role Management</Typography>
                    </Grid>
                    <Grid item >
                        <Button variant="contained" href='/user_role_management/add_role'>ADD USER ROLE</Button>
                    </Grid>
                </Grid>
                <ConfirmProvider>
                <TableData />
                </ConfirmProvider>

            </Container>
        </>
    )
}