/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './../../page/dashboard'

// import TableData from '../../components/table';

import './../sum.css'
import Button from '@mui/material/Button';
import {
    Card,
    Col,
    Row,
    // Button,
    Container,
    // Form,
    Alert,
    ListGroup
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from "@mui/material/Avatar";
import TextField from '@mui/material/TextField';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import * as userHttpServices from '../../../src/services/user_management_service';

export default function App() {
    const formSchema = Yup.object().shape({
        password: Yup.string()
          .required('Password is mendatory')
          .min(8, 'Password must be at 8 char long'),
        confirmPwd: Yup.string()
          .required('Password is mendatory')
          .oneOf([Yup.ref('password')], 'Passwords does not match'),
      })
    const [role, setRole] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [avatar, setAvatar] = useState('');
    const formOptions = { resolver: yupResolver(formSchema) }
    const { register,handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState
    const getToken = () => {
        let token = localStorage.getItem('token')
        return token
    }

    useEffect(() => {

    }, [])
    const onSubmit = (event) => {
        // event.preventDefault();
        const password = event.password
        let user_data = {
            "first_name": firstname,
            "last_name": lastname,
            "username": username,
            "password": password,
            "role_id": role,
            "email": email,

        }
        userHttpServices.addUser(user_data,getToken())
            .then((res) => {
                console.log(res)
                if (res.data.response.status) {
                    alert('Add User Success')
                }
            })
            .catch((err) => {
                alert('Add User Fail')
            })
    }
    return (
        <>
            <Container>
                <div className='header'>
                    <Header />
                </div>
                <Grid container style={{ marginTop: '6rem' }}>
                    <Grid item xs>
                        <Typography variant='h4' style={{ fontWeight: 'bold' }}>Add User</Typography>
                    </Grid>
                </Grid>

                {/* <TableData /> */}

                <Card style={{ marginTop: '1.5rem', marginRight: '15%', marginLeft: '15%' }} className="shadow p-3 mb-5 bg-white rounded">
                    <Box component="form"  onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <TextField required fullWidth id="first_name" label="ชื่อ" variant="outlined" onChange={(e) => setFirstname(e.target.value)} />
                            </Grid>
                            <Grid item xs>
                                <TextField required fullWidth id="last_name" label="นามสกุล" variant="outlined" onChange={(e) => setLastname(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                            <Grid item xs>
                                <TextField required fullWidth id="username" label="Username" variant="outlined" onChange={(e) => setUsername(e.target.value)} />
                            </Grid>
                            <Grid item xs>
                                {/* <TextField fullWidth id="outlined-basic" label="User role" variant="outlined" /> */}
                                <FormControl required fullWidth>

                                    <InputLabel id="select-label">User Role</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        id="select-label"
                                        label="User Role"
                                        onChange={(e) => setRole(e.target.value)}
                                    >
                                        <MenuItem value={1}>Collector Supervisor</MenuItem>
                                        <MenuItem value={2}>Collector Operator</MenuItem>
                                        <MenuItem value={3}>NCB Operator</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                            <Grid item xs>
                                <TextField required fullWidth id="outlined-basic" label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)}
                                 type='password' 
                                 {...register('password')}
                                 className={`form-control ${errors.password ? 'is-invalid' : ''}`} 
                                  />
                                   <div className="invalid-feedback">{errors.password?.message}</div>
                            </Grid>
                            <Grid item xs>
                                <TextField required fullWidth id="outlined-basic" label="Confirm  Password" variant="outlined" onChange={(e) => setEmail(e.target.value)}                   type='password' 
                       {...register('confirmPwd')}
                          className={`form-control ${errors.confirmPwd ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.confirmPwd?.message}</div>
                            </Grid>
                            
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                            <Grid item xs>
                                <TextField required fullWidth id="outlined-basic" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                            <Grid item xs></Grid>
                            <Grid item >
                                <Button type="submit" fullWidth variant="contained">ADD</Button>
                            </Grid>
                            <Grid item >
                                <Button variant="contained" color="error" href='/user_management' >BACK</Button>
                            </Grid>
                        </Grid>


                    </Box>
                </Card>


            </Container>
        </>
    )
}


