import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as userHttpServices from '../../../src/services/login_service';

// import logo from '../../../src/img/TFM_sign.jpg';
import logo from '../../../src/img/TFM_sign2.png';


// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';

const theme = createTheme();

export default function SignIn() {
    localStorage.clear();

    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')

    const handleSubmit = (event) => {
        event.preventDefault();

        let object = {
            "Username": username,
            "Password": password
        }

        userHttpServices.TFMLogin(object)
            .then((res) => {
                console.log(res)
                if (res.data.response.status) {
                    var data = res.data.data[0]
                    data.signIn = object

                    localStorage.setItem('data', JSON.stringify(data))

                    if ((data.org_list).length === 1) {

                        let object = {
                            "Username": data.signIn.Username,
                            "Password": data.signIn.Password,
                            "user_id": data.user_id,
                            "org_id": data.org_list[0].org_id
                        }

                        userHttpServices.LoginOrg(object)
                            .then((res) => {
                                console.log('>>>', res)
                                let token = res.data.data[0].accessToken
                                let menus = res.data.data[0].menus
                                let role = res.data.data[0].role_id

                                localStorage.setItem('menus', JSON.stringify(menus))
                                localStorage.setItem('token', token);

                                if (role === 1) {
                                    window.location.href = "/contact_management";
                                } else if (role === 2) {
                                    window.location.href = "/contact_collector";
                                } else {
                                    alert('Login failed - err')
                                }
                            })
                    } else {
                        window.location.href = "/login_org";
                    }
                }
            })
            .catch((err) => {
                // console.log(err)
                alert('The username or password is incorrect!')
            })
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" sx={{ width: '500px' }} style={{ marginTop: '8rem' }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <img fullwidth="true" src={logo} alt='' />

                    <Typography component="h1" variant="h5" marginTop={2}>
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        {/* <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid> */}
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}