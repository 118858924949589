import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from 'react';
import * as userHttpServices from '../../../src/services/user_management_service';
import { useNavigate } from "react-router-dom";
const columns = [
    { id: 'no', label: 'No', width: '3%', align: 'left' },
    { id: 'role', label: 'Role', width: '15%', align: 'left' },
    { id: 'description', label: 'Description', width: '15%', align: 'left' },
    { id: 'numberOfUser', label: 'Number of user', width: '15%', align: 'center' },
    { id: 'lasted update', label: 'Lasted Update', width: '15%', align: 'center' },
    { id: 'button1', width: '15%' },
    { id: 'button2', width: '15%' },
];

export default function StickyHeadTable() {
    const confirm = useConfirm();
    const navigate = useNavigate()
    const [role, setRole] = React.useState('')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    let token = localStorage.getItem('token')
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    }
    useEffect(() => {
        userHttpServices.getRole(token)
            .then((res) => {
                setRole(res.data.data)
            })
    }, [])
    const goEditPage = (e, row) => {
        navigate("/user_role_management/edit_role", {
            state: row,
        })
    }
    const onDelete = (e, row) => {
        let role_id = row.role_id
        let role_name = row.role_name
        confirm({ description: `Confirm  to  delete ${role_name}.` })
            .then(() => {
                userHttpServices.deleteRole(role_id, token)
                    .then((res) => {
                        if (res.data.response.status) {
                            alert('Delete Role Success')
                            userHttpServices.getRole(token)
                            .then((res) => {
                                console.log(res.data.data)
                                setRole(res.data.data)
                            })
                        }
                    })
            })
            .catch((err) => console.log("Delete fail.", err))
    }
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }} style={{ marginTop: '1.5rem' }} className="shadow mb-5 bg-white rounded">
            <TableContainer sx={{ maxHeight: 880 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ width: column.width }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(role).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell >{index + 1}</TableCell>
                                    <TableCell >{row.role_name}</TableCell>
                                    <TableCell >{row.role_desc}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{row.count}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{row.updatedAt}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }} > <Button variant="text" onClick={(e) => goEditPage(e, row)}><EditIcon />{'\u00a0'}Edit</Button></TableCell>
                                    <TableCell style={{ textAlign: 'center' }}> <Button variant="text" onClick={(e) => onDelete(e, row)}><DeleteIcon />{'\u00a0'}Delete</Button></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={role.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
    
}