import axios from "axios";
import config from '../config.json';


//production
 const ROOT_URL = "https://api-uat.transfinmational.net";
//  const ROOT_URL2 = config.PROD_API;
 const ORC_URL = "https://ocr-test.transfinmational.com"
//dev
// const ROOT_URL = config.DEV_API;
// const ROOT_URL2 = config.DEV_API;
//  const ROOT_URL = "http://localhost:3334"

export const createStatement = (value, token, callback) => {
    return axios.post(`${ROOT_URL}/statement`, value, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const getStatement = ( token,value,callback) => {
    return axios.get(`${ROOT_URL}/cal_statement/` + value, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const getAllStatement = ( token,value,callback) => {
    let url = "http://localhost:3334"
    return axios.get(`${url}/statement/get_statement/` + value, {
    // return axios.get(`${ROOT_URL2}/statement/get_statement/` + value, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const callOCRStatement = ( formData) => {
    // return axios.post(`${ORC_URL}/api/ocr`, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    // })
    return axios.post(
        `${ORC_URL}/api/ocr`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
};