import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  layout: {
    padding: {
      top: 20,
      left: 20
    },
  },
  plugins: {
    legend: {
      display: true,
      fullSize: true,
      position: 'right',
      align: 'start',
      labels: {
        padding: 20,
      },
    },
  },
};

const labels = ['นอกงบดุล', 'ในงบดุล', 'NP Pico', 'Pico ขล',];

export const data = {
  labels,
  datasets: [
    {
      label: '# of Votes',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: [
        '#F464A4',
        '#A454BC',
        '#14BCD4',
        '#1CDB8B',
      ],
      borderColor: [
        '#F464A4',
        '#A454BC',
        '#14BCD4',
        '#1CDB8B',
      ],
      borderWidth: 1,
    },
  ],
};

export default function App() {
  return <Pie options={options} data={data} />;
}