import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import test1Slice from "./slices/test1Slice"

const reducer ={
    test1Slice,
};

export const store = configureStore({
    reducer,
});

// export type RootState = ReturnType<typeof store.getState>;
// export type appDispatch = typeof store.dispatch;
// export const userAppDisatch =()=>useDispatch<appDispatch>();