
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './../../page/dashboard'
import './../sum.css'
import Button from '@mui/material/Button';
import {
    Card, Container,// Form, Alert,ListGroup
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import * as userHttpServices from '../../../src/services/user_management_service';
import { useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
export default function App() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const { state } = useLocation()
    const [user_id, setUserID] = useState(state.user_id)
    const [status, setStatus] = useState(0)
    const [password, setPassword] = useState('');
    const [old_password, setOldPassword] = useState('');

    const getToken = () => {
        let token = localStorage.getItem('token')
        return token
    }
    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is mendatory')
            .min(8, 'Password must be at 8 char long'),
        confirmPwd: Yup.string()
            .required('Password is mendatory')
            .oneOf([Yup.ref('password')], 'Passwords does not match'),
    })
    const formOptions = { resolver: yupResolver(formSchema) }
    const { register, handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState
    useEffect(() => {

    }, [])
    const onSubmitEmail = (event) => {
        event.preventDefault();

        let user_data = {
            "Email": email,

        }
        userHttpServices.resetPassword(user_data, getToken())
            .then((res) => {
                console.log(res)
                if (res.data.response.status) {
                    alert('Email Correct  Check You Email')
                    setStatus(1)
                }
            })
            .catch((err) => {
                alert('Email InCorrect')
            })
    }
    const onSubmitResetPassword = (event) => {
        const password = event.password
        let data ={
            user_id:user_id,
            password_old:old_password,
            password_new:password
        }
        userHttpServices.changePassword(data, getToken())
            .then((res) => {
                console.log(res)
                if (res.data.response.status) {
                    alert('Change Password Success')
                    setStatus(1)
                }
            })
            .catch((err) => {
                alert('Change Password Fail')
            })
    }
    {
        if (status == 0) {
            return (
                <>
                    <Container>
                        <div className='header'>
                            <Header />
                        </div>
                        <Grid container style={{ marginTop: '6rem' }}>
                            <Grid item xs>
                                <Typography variant='h4' style={{ fontWeight: 'bold' }}>Reset Password</Typography>
                            </Grid>
                        </Grid>

                        {/* <TableData /> */}

                        <Card style={{ marginTop: '1.5rem', marginRight: '15%', marginLeft: '15%' }} className="shadow p-3 mb-5 bg-white rounded">
                            <Box component="form" onSubmit={onSubmitEmail} sx={{ mt: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <TextField required fullWidth id="email" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                                    <Grid item xs></Grid>
                                    <Grid item >
                                        <Button type="submit" fullWidth variant="contained">RESET</Button>
                                    </Grid>
                                    <Grid item >
                                        <Button variant="contained" color="error" href='/user_management' >BACK</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Container>
                </>
            )

        } else {
            return (
                <>
                    <Container>
                        <div className='header'>
                            <Header />
                        </div>
                        <Grid container style={{ marginTop: '6rem' }}>
                            <Grid item xs>
                                <Typography variant='h4' style={{ fontWeight: 'bold' }}>Change Password</Typography>
                            </Grid>
                        </Grid>

                        {/* <TableData /> */}

                        <Card style={{ marginTop: '1.5rem', marginRight: '15%', marginLeft: '15%' }} className="shadow p-3 mb-5 bg-white rounded">
                            <Box component="form" onSubmit={handleSubmit(onSubmitResetPassword)} sx={{ mt: 1 }}>
                                <Grid container spacing={1} style={{ marginTop: '0.5rem' }}>
                                    <Grid item xs>
                                        <TextField required fullWidth id="outlined-basic" label="Old Password" variant="outlined" onChange={(e) => setOldPassword(e.target.value)}
                                            type='password'  helperText="Check your email"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} style={{ marginTop: '0.5rem' }}>
                                    <Grid item xs>
                                        <TextField required fullWidth id="outlined-basic" label="New Password" variant="outlined" onChange={(e) => setPassword(e.target.value)}
                                            type='password'
                                            {...register('password')}
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">{errors.password?.message}</div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} style={{ marginTop: '0.5rem' }}>

                                    <Grid item xs>
                                        <TextField required fullWidth id="outlined-basic" label="Confirm  Password" variant="outlined" onChange={(e) => setEmail(e.target.value)} type='password'
                                            {...register('confirmPwd')}
                                            className={`form-control ${errors.confirmPwd ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.confirmPwd?.message}</div>
                                    </Grid>

                                </Grid>
                                <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                                    <Grid item xs></Grid>
                                    <Grid item >
                                        <Button type="submit" fullWidth variant="contained">CHANGE</Button>
                                    </Grid>
                                    <Grid item >
                                        <Button variant="contained" color="error" href='/user_management' >BACK</Button>
                                    </Grid>
                                </Grid>


                            </Box>
                        </Card>


                    </Container>
                </>
            )

        }
    }

}


